import { useState } from "react";
import { Link } from "react-router-dom";
import Imago from "./companies.module.css";
import ReactPagination from "react-paginate";
import B from "../../assets/Banners/logo.png";
import { useFetch } from "../../hooks/customHooks";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import CompanyFilter from "../filter/companyFilter";

const Companies = () => {
  const [pageNumber, setPageNumber] = useState(0);

  // custom hook to fetch data from the backend
  const [response] = useFetch("/shopper/list_companies/");
  const companies = response?.companies;
  console.log(companies);

  const usersPerPage = 24;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = companies ? Math.ceil(companies.length / usersPerPage) : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="page-holder">
      <Header />

      {/*  Modal */}
      <div className="container">
        <section id={Imago.companies_head}>
          <div className="container p-0">
            {/* SHOP LISTING*/}
            <div className="col-lg-12 order-1 order-lg-2 mb-5 mb-lg-0">
              <div className="row mb-3 align-items-center" id="sort">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  {companies && (
                    <p className="text-small text-muted mb-0">
                      Showing {companies.length} results
                    </p>
                  )}
                </div>
                <div className="col-lg-6">
                  <ul className="list-inline d-flex align-items-center justify-content-lg-end mb-0">
                    <li className="list-inline-item text-muted mr-3">
                      <Link className="reset-anchor p-0" to="#/">
                        <i className="fas fa-th-large" />
                      </Link>
                    </li>
                    <li className="list-inline-item text-muted mr-3">
                      <Link className="reset-anchor p-0" to="#/">
                        <i className="fas fa-th" />
                      </Link>
                    </li>
                    <CompanyFilter />
                  </ul>
                </div>
              </div>
              {/*COMPANIES*/}

              <div className="row">
                {companies &&
                  companies
                    .slice(pagesVisited, pagesVisited + usersPerPage)
                    .map((item) => (
                      <div
                        className="col-lg-2 col-md-3 col-6  p-1"
                        key={item?.company_uuid}>
                        <div className="">
                          <Link
                            to={`/company?company_id=${item?.company_uuid}`}
                            className="">
                            {item?.company_logo === "No Logo" ? (
                              <img
                                className={Imago.comp}
                                src={B}
                                alt="company logo"
                                loading="lazy"
                              />
                            ) : (
                              <img
                                className={Imago.comp}
                                src={item?.company_logo}
                                alt="company logo"
                                loading="lazy"
                              />
                            )}
                          </Link>
                          <div className="py-1">
                            <small className="card-title">
                              {/* {`${item?.company_name.substring(0, 12)}..`} */}
                              {item?.company_name.length < 18
                                ? `${item?.company_name}`
                                : `${item?.company_name.substring(0, 12)}..`}
                            </small>
                            <p>
                              Industry -{" "}
                              {item?.industry.industry_name.length < 13
                                ? `${item?.industry.industry_name}`
                                : `${item?.industry.industry_name.substring(
                                    0,
                                    12
                                  )}...`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {/* END OF COMPANIES */}

              {/* PAGINATION*/}
              {companies && (
                <div className="container">
                  <ReactPagination
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    breakLabel={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination justify-content-end"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Companies;
