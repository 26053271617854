import A from "../../assets/Banners/call.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ContactUsStyles from "./contactUs.module.css";

const ContactUs = () => {
  // @desc - a function to handle the form submission for the contact us page
  // @params - e - event object
  const submitHandler = (e) => {
    console.log(e);
  };

  return (
    <>
      <div className="page-holder">
        <Header />

        <main className={ContactUsStyles.about_main}>
          <img src={A} alt="placeholder" className="img-fluid" />
          <div>
            <div className="container">
              <div className="col-lg-12">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="first_name">
                        First name
                      </label>
                      <input
                        className="form-control form-control-lg"
                        name="first_name"
                        // onChange={(e) => setFirstName(e.target.value)}
                        type="text"
                        placeholder="Enter your first name"
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="first_name">
                        Last name
                      </label>
                      <input
                        className="form-control form-control-lg"
                        name="first_name"
                        // onChange={(e) => setFirstName(e.target.value)}
                        type="text"
                        placeholder="Enter your last name"
                      />
                    </div>

                    <textarea
                      className="form-control form-control-lg"
                      rows="5"
                      cols="5"
                      name="message"
                      placeholder="Enter your message ... "
                    />
                    <br />

                    <div className="col-lg-12 form-group py-4">
                      <button className="btn btn-dark" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
