import "./footer.css";

const Footer = () => {
  let date = new Date().getFullYear();

  return (
    <footer className="bg-dark text-white">
      <div className="container">
        <div className="footer-main">
          <div className="">
            <h6 className="text-uppercase mb-3">Customer services</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  className="footer-link"
                  style={{ color: "#dcb14a" }}
                  href="/contact_us">
                  For Help, Contact Us
                </a>
              </li>

              <li>
                <a
                  className="footer-link"
                  href="/refund"
                  style={{ color: "#dcb14a" }}>
                  Returns &amp; Refunds
                </a>
              </li>

              <li>
                <a
                  className="footer-link"
                  style={{ color: "#dcb14a" }}
                  href="/terms_and_condition">
                  Terms &amp; Conditions
                </a>
              </li>

              <li>
                <a
                  className="footer-link"
                  href="/privacy"
                  style={{ color: "#dcb14a" }}>
                  Privacy
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h6 className="text-uppercase mb-3">Company</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  className="footer-link"
                  style={{ color: "#dcb14a" }}
                  href="/sell">
                  want to sell your product ?
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  style={{ color: "#dcb14a" }}
                  href="/sell">
                  Available Services
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  style={{ color: "#dcb14a" }}
                  href="/about">
                  About
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h6 className="text-uppercase mb-3">Social media</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  className="footer-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/rosmall_online">
                  <img
                    src="https://img.icons8.com/color/38/000000/twitter--v1.png"
                    alt="Twitter"
                  />
                </a>

                <a
                  className="footer-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/ros_mall/">
                  <img
                    src="https://img.icons8.com/color/38/000000/instagram-new--v2.png"
                    alt="Instagram"
                  />
                </a>

                <a
                  className="footer-link"
                  target="_blank"
                  without
                  rel="noreferrer"
                  href="https://web.facebook.com/RosMallOnline">
                  <img
                    src="https://img.icons8.com/color/38/000000/facebook-new.png"
                    alt="facebook"
                  />
                </a>

                <a
                  className="footer-link"
                  target="_blank"
                  without
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCBy5Zg0nPWa2KBG33Iyg6Ng">
                  <img
                    src="https://img.icons8.com/color/38/000000/youtube-play.png"
                    alt="Youtube"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="row">
          <div className="">
            <h6 className="text-uppercase mb-3">Customer services</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a className="footer-link" href="/contact_us">
                  For Help, Contact Us
                </a>
              </li>
              <li>
                <a className="footer-link" href="/refund">
                  Returns &amp; Refunds
                </a>
              </li>
              <li>
                <a className="footer-link" href="/privacy">
                  Privacy
                </a>
              </li>
              <li>
                <a className="footer-link" href="/terms_and_condition">
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <h6 className="text-uppercase mb-3">Company</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a className="footer-link" href="/sell">
                  Available Services
                </a>
              </li>
              <li>
                <a className="footer-link" href="/about">
                  About
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h6 className="text-uppercase mb-3">Social media</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  className="footer-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/rosmall_online">
                  <img
                    src="https://img.icons8.com/color/38/000000/twitter--v1.png"
                    alt="Twitter"
                  />
                </a>

                <a
                  className="footer-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/ros_mall/">
                  <img
                    src="https://img.icons8.com/color/38/000000/instagram-new--v2.png"
                    alt="Instagram"
                  />
                </a>

                <a
                  className="footer-link"
                  target="_blank"
                  without
                  rel="noreferrer"
                  href="https://web.facebook.com/RosMallOnline">
                  <img
                    src="https://img.icons8.com/color/38/000000/facebook-new.png"
                    alt="facebook"
                  />
                </a>

                <a
                  className="footer-link"
                  target="_blank"
                  without
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCBy5Zg0nPWa2KBG33Iyg6Ng">
                  <img
                    src="https://img.icons8.com/color/38/000000/youtube-play.png"
                    alt="Youtube"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div> */}

        <div
          className="border-top sub-footer"
          style={{ borderColor: "#1d1d1d !important" }}>
          <div className="sub-text">
            <p className="small text-muted mb-0">
              © {date} all rights reserved.
            </p>
          </div>
          <div className="sub-text">
            <p className="small text-muted mb-0">
              Designed by{" "}
              <a
                className="text-white reset-anchor"
                href="https://rosmallonline.com/">
                Rosmallonline
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
