import { MdOutlineDeleteForever } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import B from "../../assets/Banners/alll.png";
import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import CartAlert from "../../components/cart-alert/cart-alert";

const Checkout = () => {
  const [state, setState] = useState({
    userDetails: null,
  });
  const [cost, setCost] = useState(null);
  const [token, setToken] = useState(null);
  const [delivery, setDelivery] = useState("");
  const [visible, setVisible] = useState(false);
  const [cart_items, setCartItems] = useState([]);

  // todo: get the total cost of the items in cart
  const items_tot = cart_items.map((item) => {
    return item.total;
  });
  // console.log(items_tot);
  // now add the total of all items
  const total = items_tot.reduce((a, b) => a + b, 0);
  // console.log(total);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get(`${process.env.REACT_APP_Base_url}/cart/shopper_cart/`, {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          // the below line is the one that updates the state
          setCartItems(response.data.shopper_cart_data);
        }
      });

    axios
      .get(`${process.env.REACT_APP_Base_url}/shopper/view_shopper_details/`, {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setState((state) => {
          return { ...state, userDetails: res.data.shopper_details };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  // @ desc - a function to handle delete cart -@ param - id
  const deleteCart = async (id) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/cart/delete_cart_item/`,
        {
          cart_ids: [id],
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.status === 200) {
          toast.error(
            resp.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 2000 }
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
  };

  // @ desc - a function to handle payment
  // @ param - { name, currency, amount, email, phone, metadata, callback, post_url, pub_key }
  const Payment = async () => {
    await axios
      .post(
        "https://app.mybusinesspay.com/payment/v1.0/initialize",
        {
          metadata: {
            product_name: cost.cart_tag_id,
            product_description: state.userDetails.profile_id,
          },
          callback: `${process.env.REACT_APP_Base_callback_url_}`,
          post_url: `${process.env.REACT_APP_Base_url}/payment/cart_payment_callback/`,
          pub_key: process.env.REACT_APP_Pub_key,
          first_name: state.userDetails.first_name,
          currency: "GHS",
          amount: cost.grand_total_amount,
          last_name: state.userDetails.last_name,
          phone_number: state.userDetails.phone,
          email: state.userDetails.email,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_Payment_key,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          window.location.replace(response.data.url);
          // console.log(response);
        } else {
          window.location.replace(response.data.url);
          // console.log(response);
        }
      })
      .catch((error) => {
        alert("Please add an item and try again");
        console.error(error);
      });
  };

  // @ desc - a function to handle checkout
  const handleCheckout = () => {
    setVisible(true);

    const item = cart_items.map((item) => item.cart_uuid);
    console.log(item);

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/cart/tag_cart_items/`,
        {
          cart_ids: cart_items.map((item) => item.cart_uuid),
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.data.status === 201) {
          // console.log(res.data);
          setCost(res.data.payment_details);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <ToastContainer />

      <CartAlert />

      <div className="page-holder">
        <Header />

        <div className="container">
          {/* HERO SECTION*/}

          <section className="py-5">
            {/* BILLING ADDRESS*/}
            <h2 className="h5 text-uppercase mb-4">
              Billing Details & delivery address
            </h2>

            <div className="row">
              {state.userDetails && (
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="firstName">
                        First name
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="firstName"
                        type="text"
                        placeholder={state?.userDetails.first_name}
                        onChange={(e) =>
                          setState((state) => {
                            return { ...state, first_name: e.target.value };
                          })
                        }
                      />
                    </div>

                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="lastName">
                        Last name
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="lastName"
                        type="text"
                        placeholder={state?.userDetails.last_name}
                        onChange={(e) =>
                          setState((state) => {
                            return { ...state, last_name: e.target.value };
                          })
                        }
                      />
                    </div>

                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="street">
                        gps
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="street"
                        type="text"
                        placeholder={state?.userDetails.gps}
                        onChange={(e) =>
                          setState((state) => {
                            return { ...state, gps: e.target.value };
                          })
                        }
                      />
                    </div>

                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="phone">
                        Location
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="phone"
                        type="text"
                        placeholder={state?.userDetails.location}
                        onChange={(e) =>
                          setState((state) => {
                            return { ...state, location: e.target.value };
                          })
                        }
                      />
                    </div>

                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="phone">
                        Region
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="phone"
                        type="text"
                        placeholder={state?.userDetails.region}
                        onChange={(e) =>
                          setState((state) => {
                            return { ...state, region: e.target.value };
                          })
                        }
                      />
                    </div>

                    <div className="col-lg-6 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="phone">
                        Phone number
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="phone"
                        type="tel"
                        placeholder={state?.userDetails.phone}
                        onChange={(e) =>
                          setState((state) => {
                            return { ...state, phone: e.target.value };
                          })
                        }
                      />
                    </div>

                    <div className="col-lg-6 form-group d-none">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="address">
                        Country
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="address"
                        type="text"
                        placeholder={state?.userDetails.country}
                        onChange={(e) =>
                          setState((state) => {
                            return { ...state, country: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* ORDER SUMMARY*/}
              <div className="col-lg-5">
                <div className="card border-0 rounded-0 p-lg-4 bg-light">
                  <div className="card-body">
                    <h5 className="text-uppercase mb-4">Your order</h5>
                    <ul className="list-unstyled mb-0">
                      {cart_items &&
                        cart_items.map((item, index) => {
                          return (
                            <>
                              <li
                                key={item?.cart_uuid}
                                className="d-flex align-items-center justify-content-between">
                                <strong className="small font-weight-bold">
                                  {/* {item?.product.product_name} */}
                                  {item?.product.product_name.length < 25
                                    ? `${item?.product.product_name}`
                                    : `${item?.product.product_name.substring(
                                        0,
                                        19
                                      )}...`}
                                </strong>
                                <span className="text-muted small">
                                  GH₵ {item?.total}
                                </span>
                                <span className="text-muted small">
                                  <MdOutlineDeleteForever
                                    color="red"
                                    size={20}
                                    onClick={() => deleteCart(item?.cart_uuid)}
                                  />
                                </span>
                              </li>
                            </>
                          );
                        })}

                      <li className="border-bottom my-2" />
                      {total && (
                        <li className="d-flex align-items-center justify-content-between">
                          <strong className="text-uppercase small font-weight-bold">
                            Total
                          </strong>
                          <span>GH₵ {total}</span>
                        </li>
                      )}
                      <div className="container-fluid py-5">
                        <h5 className="text-uppercase mb-4">
                          {" "}
                          Payment Channels
                        </h5>
                        <img
                          src={B}
                          className="img-fluid"
                          alt="all"
                          loading="lazy"
                        />
                        <ul className="list-unstyled mb-0 py-3">
                          <li className="border-bottom my-2" />
                          <li className="d-flex align-items-center justify-content-between">
                            <strong className="text-uppercase small font-weight-bold">
                              Bank Transfer
                            </strong>
                            <span />
                          </li>
                          <li className="border-bottom my-2" />
                        </ul>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* payment confirmation modal */}

            <section className="py-3">
              <div className="card flex justify-content-center">
                <Button
                  label="Proceed"
                  icon="pi pi-external-link"
                  onClick={() => handleCheckout()}
                />
                <Dialog
                  header="Payment Process"
                  maximizable
                  visible={visible}
                  style={{ width: "90vw" }}
                  onHide={() => setVisible(false)}>
                  <>
                    <section>
                      <h6>
                        <span className="text-danger"> NB: </span>
                        <span className="mx-2">
                          Free delivery on purchases above GH₵ 400 within Accra
                        </span>
                      </h6>
                    </section>
                    <br />
                  </>

                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      Select Delivery Option
                    </label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => setDelivery(e.target.value)}>
                      <option value="">---- select ----</option>

                      <option value="standard"> Standard </option>

                      <option value="self-pickup">Self pickup – Free</option>
                    </select>
                  </div>

                  <>
                    {delivery === "standard" && (
                      <div className="col-lg-12">
                        <div className="card border-0 rounded-0 p-lg-4 bg-light">
                          <div className="card-body">
                            <h5 className="text-uppercase mb-1">
                              Standard Delivery (within 72hrs)
                            </h5>
                            {/* <hr /> */}
                            {/* <h5 className="text-lowercase mb-1">
                              delivery to
                              {state?.userDetails && (
                                <span className="text-danger text-bold px-2">
                                  {state.userDetails.shopper.address}{" "}
                                </span>
                              )}
                            </h5> */}
                            <hr />
                            <ul className="list-unstyled mb-0">
                              <li className="d-flex align-items-center justify-content-between py-1">
                                <strong className="text-uppercase small font-weight-bold">
                                  Weight - W (kg)
                                </strong>
                              </li>
                              <li className="d-flex align-items-center justify-content-between py-1">
                                <strong className="text-uppercase small font-weight-bold">
                                  Price - P (GH¢)
                                </strong>
                              </li>
                              <li
                                className="d-flex align-items-center justify-content-between pt-3"
                                style={{
                                  overflowX: "scroll",
                                }}>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">1kg</th>
                                      <th scope="col">2kg</th>
                                      <th scope="col">3kg</th>
                                      <th scope="col">4kg</th>
                                      <th scope="col">5kg</th>
                                      <th scope="col">6kg</th>
                                      <th scope="col">7kg</th>
                                      <th scope="col">8kg</th>
                                      <th scope="col">9kg</th>
                                      <th scope="col">10kg</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row"></th>
                                      <td>₵18</td>
                                      <td>₵21</td>
                                      <td>₵24</td>
                                      <td>₵27</td>
                                      <td>₵30</td>
                                      <td>₵33</td>
                                      <td>₵36</td>
                                      <td>₵39</td>
                                      <td>₵42</td>
                                      <td>₵45</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </li>

                              <li className="d-flex align-items-center justify-content-between pt-5">
                                <strong className="text-uppercase small font-weight-bold text-danger">
                                  NB - Delivery charges are subject to '+3GHS
                                  Per Extra KG
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                    {delivery === "self-pickup" && (
                      <div className="col-lg-12">
                        <div className="card border-0 rounded-0 p-lg-4 bg-light">
                          <div className="card-body">
                            <h5 className="text-uppercase mb-1 text-center">
                              It's Free
                            </h5>
                            <hr />
                          </div>
                        </div>
                      </div>
                    )}
                  </>

                  <br />
                  <button
                    className="btn btn-primary btn-sm btn-block"
                    type="submit"
                    onClick={Payment}>
                    Place order
                  </button>
                </Dialog>
              </div>
            </section>

            {/* payment confirmation modal */}
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Checkout;
