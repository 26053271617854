import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const Newsletter = () => {
  const [user, setUser] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/add_subscriber/`,
          {
            email: user,
          },
          {
            headers: {
              "X-SHOPPER-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          console.log(res.data);

          toast.success(
            res.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.warn(
        "Please login to subscribe",
        {
          position: toast.POSITION.TOP_RIGHT,
        },
        { autoClose: 10000 }
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="py-5">
        <div className="container p-0">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <h5 className="text-uppercase">Let's be friends!</h5>
              <p className="text-small text-muted mb-0">
                Subscribe to our news letter to get the latest and hottest deals
                on our site at anytime.
              </p>
            </div>
            <div className="col-lg-6">
              <form onSubmit={handleSubmit}>
                <div className="input-group flex-column flex-sm-row mb-3">
                  <input
                    className="form-control form-control-lg py-3"
                    type="email"
                    required
                    placeholder="Enter your email address"
                    onChange={(e) => setUser(e.target.value)}
                    aria-describedby="button-addon2"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary btn-block"
                      //   className="btn btn-dark btn-block"
                      id="button-addon2"
                      type="submit">
                      Subscribe
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Newsletter;
