import React from "react";
import { useSelector } from "react-redux";
import { AiFillTags } from "react-icons/ai";
import ProductsTable from "../productsTable/productsTable";

const HotDeals = () => {
  // TODO - get the trending list from the Redux store
  const { trend_list } = useSelector((state) => state.shopper);
  const slice_trend = trend_list.slice(25, 49);

  return (
    <>
      <div>
        <section className="container py-4">
          <header>
            <h2
              className="h4 text-uppercase mb-4"
              id="deals"
              style={{
                color: "#000",
                borderBottom: "5px solid red",
                margin: "-15px",
              }}>
              <AiFillTags
                className="mob_size"
                // size={32}
                style={{ color: "#fca103" }}
              />
              <span className="pl-2">Hot deals</span>
            </h2>
          </header>

          <ProductsTable data={slice_trend} />

          <br />
        </section>
      </div>
    </>
  );
};

export default HotDeals;
