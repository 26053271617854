import React from "react";
import "primeicons/primeicons.css";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-orange/theme.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Sell from "./pages/sell/sell";
import Login from "./pages/login/login";
import ScrollToTop from "./ScrollToTop";
import Company from "./pages/company/company";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./pages/dashboard/dashboard";
import About from "./pages/rosmall/about/about";
import Review from "./pages/user/review/review";
import Checkout from "./pages/checkout/checkout";
import Refund from "./pages/rosmall/refund/refund";
import Companies from "./pages/companies/companies";
import ErrorPage from "./pages/errorPage/errorPage";
import ContactUs from "./pages/contact-Us/contactUs";
import Register from "./pages/registration/register";
import Privacy from "./pages/rosmall/privacy/privacy";
import ProfileCart from "./pages/user/cart/profileCart";
import Products from "./pages/products/general/products";
import ActivationPage from "./pages/activation/activation";
import DisplaySearch from "./pages/advance page/displaySearch";
import AdvanceSearch from "./pages/advance page/advanceSearch";
import ReturnPolicy from "./pages/rosmall/return/returnPolicy";
import RegisterShopper from "./pages/registration/registerShopper";
import ProductSubs from "./pages/products/sub category/productSubs";
import UpdateProfile from "./pages/user/update profile/updateProfile";
import ResetPassword from "./pages/password/reset password/resetPassword";
import ProductDetails from "./pages/details/productDetails/productDetails";
import ShoppingHistory from "./pages/user/shopping history/shoppingHistory";
import ForgotPassword from "./pages/password/forgot password/forgotPassword";
import TermsAndCondition from "./pages/rosmall/terms & conditions/termsAndCondition";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/review" element={<Review />} />
          <Route path="/company" element={<Company />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/search" element={<AdvanceSearch />} />
          <Route path="/products/:main" element={<Products />} />
          <Route path="/profile/cart" element={<ProfileCart />} />
          <Route path="/activation" element={<ActivationPage />} />
          <Route path="/return_policy" element={<ReturnPolicy />} />
          <Route path="/search/results" element={<DisplaySearch />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/product/details" element={<ProductDetails />} />
          <Route path="/products/:sub/:sub" element={<ProductSubs />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/register/shopper" element={<RegisterShopper />} />
          <Route path="/terms_and_condition" element={<TermsAndCondition />} />

          <Route element={<ProtectedRoute />}>
            <Route
              path="/profile/shopping_history"
              element={<ShoppingHistory />}
            />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/profile/update" element={<UpdateProfile />} />
          </Route>

          <Route path="*" element={<ErrorPage />} />

          {/* <Route path="/return" element={<Return />} /> */}
          {/* <Route path="/comments" element={<Comments />} /> */}
          {/* // !  yet to work on */}
          {/* <Route path="/change_password" element={<ChangePassword />} /> */}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
