import axios from "axios";
import React, { useEffect } from "react";
import { AiTwotoneFire } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import ProductsTable from "../productsTable/productsTable";
import { updateTrendingList } from "../../redux/slice/shopperSlice";

const TrendingDashboard = () => {
  const dispatch = useDispatch();
  const { trend_list } = useSelector((state) => state.shopper);
  const slice_trend = trend_list.slice(0, 24);

  useEffect(() => {
    let isMounted = false;
    axios
      .get(`${process.env.REACT_APP_Base_url}/shopper/get_trending_posts/`, {
        headers: {
          "x-user-pass-auth":
            "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
        },
      })
      .then((res) => {
        if (!isMounted && res.data?.status === 200) {
          // the below line is the one that updates the state
          dispatch(updateTrendingList(res.data.trending_products));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      isMounted = true;
    };
  }, [dispatch]);

  return (
    <section className="container pt-4 pb-2">
      <header>
        <h2
          className="h4 text-uppercase mb-4"
          id="deals"
          style={{
            color: "#000",
            borderBottom: "5px solid red",
            margin: "-15px",
          }}>
          <span className="pr-2">Top trending</span>
          <AiTwotoneFire
            // size={32}
            className="mob_size"
            style={{ color: "#fca103", margin: "4px 0px" }}
          />
        </h2>
      </header>

      <ProductsTable data={slice_trend} />
      <br />
    </section>
  );
};

export default TrendingDashboard;
