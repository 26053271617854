import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import PaymentAlertImage from "../../assets/payment-alert/momo.png";

const PaymentAlert = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header=""
        visible={visible}
        className="payment-alert"
        onHide={() => setVisible(false)}>
        <p className="m-2">
          <img
            src={PaymentAlertImage}
            alt="payment-alert"
            className="payment-alert-image"
          />
        </p>
      </Dialog>
    </div>
  );
};

export default PaymentAlert;
