import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const Register = () => {
  return (
    <div style={{ height: "100vh" }}>
      <div className="page-holder">
        <Header />

        <div className="container py-5" style={{ height: "60vh" }}>
          <section className="py-5">
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">As a Shopper</h5>
                    <p className="card-text">
                      You enjoy shopping and you want to save money whiles doing
                      that, then sign up now and enjoy amazing discounts.
                    </p>
                    <Link
                      to="shopper"
                      className="btn btn-primary reg_btn"
                      reloadDocument>
                      Register Here
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">As a Company / Organization</h5>
                    <p className="card-text">
                      Do you have a company or organization and you want to sell
                      your products, then sign up now and reach out to millions
                      with ease.
                    </p>
                    <Link
                      to="/sell"
                      className="btn btn-primary reg_btn"
                      reloadDocument>
                      Register Here
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Register;
