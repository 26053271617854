import { createSlice } from "@reduxjs/toolkit";

// get the cart from local storage
const cartFromLocalStorage =
  localStorage.getItem("temporaryCart") === null
    ? []
    : JSON.parse(localStorage.getItem("temporaryCart"));

export const shopperSlice = createSlice({
  name: "USER",
  initialState: {
    shopper_details: {},
    cart_items: [],
    trend_list: [],
    companies_list: [],
    subproducts_list: [],
    users_change: [],
    subCategories: [],
    productCategory: [],
    temporaryCart: cartFromLocalStorage,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.shopper_details = action.payload;
    },
    updateCartList: (state, action) => {
      state.cart_items = action.payload;
    },
    updateTrendingList: (state, action) => {
      state.trend_list = action.payload;
    },

    updateCompaniesList: (state, action) => {
      state.companies_list = action.payload;
    },
    updateSubProductsList: (state, action) => {
      state.subproducts_list = action.payload;
    },
    updateUsersChange: (state, action) => {
      state.users_change = action.payload;
    },
    updateSubCategories: (state, action) => {
      state.subCategories = action.payload;
    },
    updateProductsCategory: (state, action) => {
      state.productCategory = action.payload;
    },
    setTemporaryCart: (state, action) => {
      state.temporaryCart.push(action.payload);

      // save the cart to local storage
      localStorage.setItem(
        "temporaryCart",
        JSON.stringify(state.temporaryCart)
      );
    },
  },
});

export const {
  setUserInfo,
  updateCartList,
  updateTrendingList,
  updateCompaniesList,
  updateSubProductsList,
  updateUsersChange,
  updateSubCategories,
  updateProductsCategory,
  setTemporaryCart,
} = shopperSlice.actions;

export default shopperSlice.reducer;
