import axios from "axios";
import React, { useState } from "react";
import { useFetch, useFetchWithoutToken } from "../../hooks/customHooks";

const ProductFilter = () => {
  const [product_name, setProduct] = useState("");
  const [product_id, setProductId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [region, setRegion] = useState("");

  const [response] = useFetch("/setup/get_regions/");
  const regionList = response?.regions;

  const [response1] = useFetchWithoutToken("/setup/list_product_category/");
  const cat = response1?.product_category;

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/product_filter/`,
        {
          product_name: product_name,
          product_id: product_id,
          price: [from, to],
          region_id: region,
          condition: "",
          delivery: "",
          returnable: "",
          offset: "1",
          record_number: "10",
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
          },
        }
      )
      .then((resp) => {
        // console.log(resp.data);
        alert(resp.data.message);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <div className="">
      {/* Button trigger modal */}
      <button
        type="button"
        className="form-control btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
        style={{ backgroundColor: "#0e8ce4", color: "#fff" }}>
        Click here to Filter
      </button>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Filter Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="container px-5">
                  <div class="container py-5">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputEmail4">
                            Search by Product name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Product name"
                            onChange={(e) => setProduct(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-6">
                          <label htmlFor="inputAddress2">
                            Price range from
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputAddress2"
                            placeholder="Price range from"
                            onChange={(e) => setFrom(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-6">
                          <label htmlFor="inputAddress2">Price range to</label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputAddress2"
                            placeholder="Price range to"
                            onChange={(e) => setTo(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      <hr />

                      <div className="col-12">
                        <label htmlFor="inputAddress">Region of Location</label>
                        {regionList && (
                          <div className="form-group  col-12">
                            <select
                              name="account"
                              className="form-control mb-3"
                              style={{ width: "auto" }}
                              onChange={(e) => setRegion(e.target.value)}>
                              <option value="-----" selected>
                                ---
                              </option>
                              {regionList.map((item, key) => {
                                return (
                                  <option value={item.region_id} key={key}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                      <hr />

                      <div className="col-12">
                        <label htmlFor="inputAddress">Products</label>
                        {cat && (
                          <div className="form-group  col-12">
                            <select
                              name="account"
                              className="form-control mb-3"
                              style={{ width: "auto" }}
                              onChange={(e) => setProductId(e.target.value)}>
                              <option value="-----" selected>
                                ---
                              </option>
                              {cat.map((item, key) => {
                                return (
                                  <option
                                    value={item.product_category_id}
                                    key={key}>
                                    {item.product_category_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                      <hr />

                      <div className="modal-footer">
                        <button type="submit" className="btn btn-primary">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
