import RelatedProducts from "../../related products/product related/relatedProducts";
import CompanyProducts from "../../related products/company related/companyProduct";
import { setTemporaryCart } from "../../../redux/slice/shopperSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import ProductReviews from "../../reviews/productReviews";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import DetailStyles from "./productDetails.module.css";
import { ToastContainer, toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import axios from "axios";

const ProductDetails = () => {
  const { search } = useLocation();
  const { items_id } = queryString.parse(search);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [input, setInput] = useState(1);
  const [loading, setLoading] = useState(true);
  const [product_type_size, setProductTypeSize] = useState("");
  const [product, setProductDetails] = useState({ productDetails: null });

  useEffect(() => {
    if (items_id) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/get_product_details/`,
          {
            product_id: items_id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setProductDetails((product) => {
            return { ...product, productDetails: res.data.product_details };
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [items_id]);

  // @ desc - add to cart - @ params - id
  const AddToTempCart = (id) => {
    if (
      input === "" ||
      input === null ||
      input <= "0" ||
      input > id.stock_number
    ) {
      toast.warn(
        "Quantity cannot be zero or greater than items in stock",
        {
          position: toast.POSITION.TOP_RIGHT,
        },
        { autoClose: 2000 }
      );
    } else {
      toast.success(
        "Item added to cart",
        {
          position: toast.POSITION.TOP_RIGHT,
        },
        { autoClose: 2000 }
      );
      // append each item clicked to the redux store
      dispatch(
        setTemporaryCart({
          product_details: id,
          quantity: parseInt(input),
          product_type_size: product_type_size,
          total_price: parseInt(input) * parseInt(id.new_price),
        })
      );
    }
  };

  // @ desc - a function buy an item - @ params - id

  const BuyNow = (id) => {
    dispatch(
      setTemporaryCart({
        product_details: id,
        quantity: parseInt(input),
        product_type_size: product_type_size,
        total_price: parseInt(input) * parseInt(id.new_price),
      })
    );

    setTimeout(() => {
      navigate("/profile/cart", { replace: true });
    }, 2000);
  };

  // @ desc - a function to share a link - @ params - id
  const shareIt = (id) => {
    navigator.clipboard.writeText(
      `https://rosmallonline.com/product/details?items_id=${id}`
    );
    toast.success("Link Copied to clipboard");
  };

  return (
    <>
      <ToastContainer />
      <div className="page-holder bg-light">
        <Header /> <hr />
        {loading && (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              margin: "auto",
              display: "block",
              padding: "30px 0",
            }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <section className={DetailStyles.details_main}>
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-5">
                {/* PRODUCT SLIDER*/}
                <div className="row m-sm-0">
                  {product?.productDetails && !loading && (
                    <div className="col-sm-10 order-1 order-sm-2">
                      <a
                        className="d-block"
                        href={product?.productDetails.image_url}>
                        <img
                          className="img-fluid"
                          id={DetailStyles.pro}
                          src={product?.productDetails.image_url}
                          loading="lazy"
                          alt="..."
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {/* PRODUCT DETAILS*/}
              {!loading && product?.productDetails && (
                <div className="col-lg-7">
                  <Link
                    to={`/company?company_id=${product?.productDetails.company.company_uuid}`}
                    className="ml-auto font-weight-bold text-uppercase pt-2"
                    id={DetailStyles.comName}
                    style={{ fontSize: "1.5rem" }}>
                    {product?.productDetails.company.company_name}
                  </Link>
                  <hr />

                  <h4 className={DetailStyles.h4}>
                    {product?.productDetails.product_name}
                  </h4>
                  <p className="text-muted lead">
                    GH₵
                    {product?.productDetails.discount === " "
                      ? `${product?.productDetails.old_price}`
                      : `${product?.productDetails.new_price}`}
                  </p>
                  <div className="row align-items-stretch mb-4">
                    <div className="col-sm-5 pr-sm-0 py-1">
                      <div className="border d-flex align-items-center justify-content-between py-1 px-3 bg-white border-white">
                        <span className="small text-uppercase text-gray mr-4 no-select">
                          Quantity
                        </span>

                        <div className="quantity">
                          <button className="dec-btn p-0">
                            <i className="fas fa-caret-left" />
                          </button>
                          <input
                            className="form-control border-0 shadow-0 p-0"
                            type="number"
                            onChange={(e) => setInput(e.target.value)}
                            defaultValue={1}
                          />
                          <button className="inc-btn p-0">
                            <i className="fas fa-caret-right" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 pl-sm-0 py-1">
                      <button
                        className="btn btn-primary btn-sm btn-block h-100 d-flex align-items-center justify-content-center px-0"
                        onClick={() => AddToTempCart(product.productDetails)}>
                        Add to cart
                      </button>
                    </div>
                    <div className="col-sm-3 pl-sm-0 py-1">
                      <button
                        className="btn btn-primary btn-sm btn-block h-100 d-flex align-items-center justify-content-center px-0"
                        onClick={() => BuyNow(product?.productDetails)}>
                        Buy now
                      </button>
                    </div>
                  </div>
                  <br />

                  {product?.productDetails.product_sizes !== " " && (
                    <>
                      {product?.productDetails.product_sizes && (
                        <div className="col-sm-6 pl-sm-0 pb-2">
                          <h5 className="text-uppercase py-1">
                            Variations Available
                          </h5>

                          <span className="d-flex">
                            <div className={DetailStyles.selectBtn}>
                              <select
                                name="industry"
                                className="form-control mb-3"
                                required
                                // style={{ width: "auto" }}
                                onChange={(e) =>
                                  setProductTypeSize(e.target.value)
                                }>
                                <option value=""> --- select size --- </option>
                                {product?.productDetails.product_sizes
                                  .split(",")
                                  .map((item, key) => {
                                    return (
                                      <option value={item} key={key}>
                                        {item}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  {/* <br /> */}
                  <ul
                    className="list-unstyled small d-inline-block"
                    id={DetailStyles.details_list}>
                    <li className="px-3 py-2 mb-1 bg-white">
                      <strong className="text-uppercase text-dark">
                        Reference number:
                      </strong>
                      <a
                        className="reset-anchor ml-2 text-uppercase text-dark"
                        href="#/"
                        id="return">
                        <b style={{ color: "red" }}>
                          {product?.productDetails.reference_number}
                        </b>
                      </a>
                    </li>

                    <li className="px-3 py-2 mb-1 bg-white text-muted">
                      <strong className="text-uppercase text-dark">
                        Items in stock:
                      </strong>
                      <a className="reset-anchor ml-2" href="#/">
                        <strong style={{ color: "red" }}>
                          {product?.productDetails.stock_number}
                        </strong>
                      </a>
                    </li>

                    <li className="px-3 py-2 mb-1 bg-white">
                      <strong className="text-uppercase text-dark">
                        Item Returnable:
                      </strong>
                      <a
                        className="reset-anchor ml-2 text-uppercase text-dark"
                        href="#/"
                        id="return">
                        <b style={{ color: "red" }}>
                          {product?.productDetails.returnable}
                        </b>
                      </a>
                    </li>

                    <li className="px-3 py-2 mb-1 bg-white text-muted">
                      <strong className="text-uppercase text-dark">
                        Delivery Available:
                      </strong>
                      <a className="reset-anchor ml-2" href="#/">
                        {product?.productDetails.delivery}
                      </a>
                    </li>

                    <li className="px-3 py-2 mb-1 bg-white text-muted">
                      <strong className="text-uppercase text-dark">Tag:</strong>
                      <a className="reset-anchor ml-2" href="#/">
                        {product?.productDetails.product_type}
                      </a>
                    </li>

                    {product?.productDetails.returnable !== "false" && (
                      <li className="px-3 py-2 mb-1 bg-white text-muted">
                        <strong className="text-uppercase text-dark">
                          Return duration:
                        </strong>
                        <a className="reset-anchor ml-2" href="#/">
                          <strong style={{ color: "red" }}>
                            {product?.productDetails.return_duration}
                          </strong>
                        </a>
                      </li>
                    )}

                    <li className="text-muted" id={DetailStyles.shareBtn}>
                      <button
                        className="btn btn-secondary btn-sm  h-100 d-flex align-items-center justify-content-center"
                        onClick={() =>
                          shareIt(product.productDetails.product_uuid)
                        }>
                        <span className="m-2">
                          <i
                            className="fas fa-share-alt fa-1x"
                            aria-hidden="true"></i>
                        </span>
                        Click to share
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <>
              <div className="card">
                <TabView>
                  <TabPanel header="Description">
                    {product?.productDetails && (
                      // display html tags
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product?.productDetails.description,
                        }}></div>
                    )}
                  </TabPanel>
                  <TabPanel header="Reviews">
                    {product?.productDetails && (
                      <ProductReviews product={product} />
                    )}
                  </TabPanel>
                </TabView>
              </div>
            </>
            <br />

            {/* DETAILS TABS*/}

            {/* COMPANY PRODUCTS*/}
            {product?.productDetails && (
              <CompanyProducts
                company_value={product?.productDetails.company}
              />
            )}

            {/* RELATED PRODUCTS*/}
            {product?.productDetails && (
              <RelatedProducts
                product_value={
                  product?.productDetails.category.product_category_id
                }
              />
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ProductDetails;
