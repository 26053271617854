import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Stylus from "./slidder.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import E from "../../assets/Ads/Ps4.png";
import G from "../../assets/Ads/Hi tops.png";
import B from "../../assets/Ads/Speaker Box.png";
import D from "../../assets/Ads/Xiomi laptop.png";
import F from "../../assets/Ads/Speaker Box 2.png";
import Ad from "../../assets/New Banners/1.png";
import Ads from "../../assets/New Banners/promote your business.png";

const SliddersDashboard = () => {
  return (
    <div className="">
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
        <SwiperSlide>
          <img
            loading="lazy"
            className={Stylus.pro}
            src={Ad}
            alt=".."
            width="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading="lazy"
            className={Stylus.pro}
            src={Ads}
            alt=".."
            width="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading="lazy"
            className={Stylus.pro}
            src={B}
            alt=".."
            width="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading="lazy"
            className={Stylus.pro}
            src={D}
            alt=".."
            width="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading="lazy"
            className={Stylus.pro}
            src={E}
            alt=".."
            width="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading="lazy"
            className={Stylus.pro}
            src={F}
            alt=".."
            width="100%"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading="lazy"
            className={Stylus.pro}
            src={G}
            alt=".."
            width="100%"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SliddersDashboard;
