import { usePost } from "../../hooks/customHooks";
import { Link } from "react-router-dom";
import Imago from "./Image.module.css";

const CompanyItems = (props) => {
  // console.log(props);

  const [response] = usePost(
    "/shopper/list_company_post/",
    props.data.company_uuid
  );
  const details = response?.active_post;
  // console.log(details);

  return (
    <div className="container">
      {details && (
        <h2 className="h5 text-uppercase items_head">
          Other(s) from {props.data.company_name}
        </h2>
      )}
      <div className="row item_row">
        {/* PRODUCT*/}
        {details ? (
          details.map((detail) => {
            return (
              <div
                className="col-lg-2 col-md-3 col-6 p-1"
                key={detail?.product_uuid}>
                <div className="product skel-loader">
                  <div className="mb-3 position-relative">
                    {detail?.discount === "0.00" ? null : (
                      <div className="badge text-white badge-danger">
                        {detail?.discount}%
                      </div>
                    )}

                    <Link
                      className="d-block"
                      to={`/product/details?items_id=${detail?.product_uuid}`}>
                      <img
                        className={Imago.imago}
                        src={detail?.image_url}
                        alt="..."
                        loading="lazy"
                      />
                    </Link>
                    <div className="product-overlay"></div>
                  </div>
                  <h6>
                    {" "}
                    <Link
                      className="reset-anchor"
                      to={`/product/details?items_id=${detail?.product_uuid}`}>
                      {detail?.product_name.length < 21
                        ? `${detail?.product_name}`
                        : `${detail?.product_name.substring(0, 20)}..`}
                    </Link>
                  </h6>

                  <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                    {/* am conditionally rendering the discount price if not display the real price */}
                    <span className="px-1">
                      {detail?.discount !== "0.00"
                        ? `GH₵ ${""} ${detail?.new_price}`
                        : `GH₵ ${""} ${detail?.new_price}`}
                    </span>

                    {detail?.discount !== "0.00" ? (
                      <del className="px-2" style={{ color: "red" }}>
                        GH₵ {""} {detail?.old_price}
                      </del>
                    ) : null}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="container">
            <p className="text-center">No Records Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyItems;
