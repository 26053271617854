import Ads from "../../components/ads/ads";
import Ads2 from "../../components/ads/ads2";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Banners from "../../components/banners/banners";
import HotDeals from "../../components/hotDeals/hotDeals";
import Newsletter from "../../components/newsletter/newsletter";
import SliddersDashboard from "../../components/slider/slidersDashboard";
import TrendingDashboard from "../../components/trendingProducts/trendingDashboard";
import PaymentAlert from "../../components/payment-alert/payment-alert";

const Home = () => {
  return (
    <section>
      <Header />

      <PaymentAlert />

      <SliddersDashboard />
      <div className="container-fluid">
        <TrendingDashboard />

        <Ads />

        <HotDeals />

        <Ads2 />

        <Banners />
        <hr />

        <Newsletter />
      </div>

      <Footer />
    </section>
  );
};

export default Home;
