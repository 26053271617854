import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import C from "../../assets/Ads/ear buds.png";
import D from "../../assets/Ads/Gaming Keyboard.png";
import E from "../../assets/Ads/gaming laptop.png";
import B from "../../assets/Ads/Gaming mouse and Keyboard.png";
import Add from "../../assets/Ads/Gaming mouse.png";
import Ad from "../../assets/Ads/ice chest 2.png";

const Ads = () => {
  return (
    <div>
      <>
        <Swiper
          slidesPerView={2}
          spaceBetween={5}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.25": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.50": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            "@1.00": {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            "@1.50": {
              slidesPerView: 2,
              spaceBetween: 10,
            },
          }}
          className="mySwiper">
          <SwiperSlide>
            <img loading="lazy" className="" src={Add} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img loading="lazy" className="" src={C} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img loading="lazy" className="" src={Ad} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img loading="lazy" className="" src={D} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img loading="lazy" className="" src={B} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img loading="lazy" className="" src={E} alt=".." width="100%" />
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
};

export default Ads;
