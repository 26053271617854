import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";

const Refund = () => {
  return (
    <div className="">
      <Header />
      <div className="container px-1">
        <div className="container text-left px-3 py-2 m-2">
          <h2 className="h4 text-bold pt-2">Return and Refund Policy</h2>
          <p>Last updated: September 19, 2022</p>
          <p>Thank you for shopping at RosMallOnline.</p>
          <p>
            If, for any reason, You are not completely satisfied with a purchase
            We invite You to review our policy on refunds and returns. This
            Return and Refund Policy has been created with the help of the
            Return and Refund Policy Generator.
          </p>
          <p>
            The following terms are applicable for any products that You
            purchased with Us
          </p>

          <h2 className="h4 text-bold">Interpretation and Definitions</h2>
          <hr />
          <h4 className="h6 py-2 text-bold">Interpretation</h4>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>

          <h5 className="h6 text-bold">Definitions</h5>
          <p>For the purposes of this Return and Refund Policy:</p>
          <ul className="list-inline mb-1 text-xs pl-5">
            <li className="nav-item dropdown">
              <p>
                <strong>Company </strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to RosMall Company
                Ltd, Brenya Street, House number 15, Apenkwa Tesano, Accra
                Ghana.
              </p>
            </li>
            <li>
              <p>
                <strong>Goods </strong> refer to the items offered for sale on
                the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Orders </strong> mean a request by You to purchase Goods
                from Us.
              </p>
            </li>
            <li>
              <p>
                <strong>Service </strong> refers to the Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Website </strong> refers to Rosmallonline, accessible
                from www.rosmallonline.com
              </p>
            </li>
            <li>
              <p>
                <strong>You </strong> means the individual accessing or using
                the Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>

          <h5 className="h4 text-bold pt-2">Your Order Cancellation Rights</h5>
          <hr />
          <p>
            You have the right to cancel an Order made within 24 hours from the
            time the order is made.
          </p>
          <p>
            In order to exercise Your right of cancellation, you must inform us
            of your decision by means of a clear statement. You can inform us of
            your decision by visiting the “contact us” page on our website:
            https://rosmallonline.com/contact_us
          </p>
          <p>
            In order to exercise Your right of cancellation, You must inform Us
            of your decision by means of a clear statement. You can inform us of
            your decision by:
          </p>
          <p>
            RosmallOnline will reimburse you no later than 3 working days from
            the day on which Rosmallonline received your payment. We will use
            the same means of payment as You used for the Order, and you will
            not incur any fees for such reimbursement.
          </p>

          <h5 className="h4 text-bold pt-2">Returning Goods</h5>
          <hr />
          <p className="px-3">
            You are entitled to return Your purchase(s) within the days
            specified by the seller with a detailed reason for doing so if the
            product is labeled returnable by the seller. The return of a
            product(s) must be made within the days specified by the seller;
            from the day the buyer or an authorized third party received the
            said product.
          </p>
          <p className="px-3">
            The seller will reimburse you no later than 3 working days from the
            day on which the seller receives the returned Goods. The Seller will
            use the same means of payment as You used for the Order, and You
            will not incur any fees for such reimbursement.
          </p>
          <p className="px-3">
            Products purchased on Rosmallonline should always be returned
            directly to the company that previously owned the item.
            Rosmallonline is a third party offering advertisement services.
            Therefore, Rosmallonline cannot under any circumstance accept return
            of already delivered good from a customer at our premises. To return
            a product bought on our site, go to shopping history under your
            account to initiate the delivery process.
          </p>

          <h4 className="h4 py-2 text-bold">
            Delivery Cost for Product(s) Return
          </h4>
          <hr />
          <p>
            If the product(s) the shopper received does not meet the
            specifications as presented by the seller on RosmallOnline, wrong
            product or the product has any defect, the seller is obliged to pay
            for the return delivery cost.
          </p>
          <p>
            If the shopper is returning the product(s) due to his/her loss of
            interest in the product, he/she is supposing to foot the return
            delivery bill.
          </p>
          <br />

          <h5 className="h4 text-bold pt-2">Eligibility for Returns</h5>
          <hr />
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
          </p>
          <ul>
            <li className="pl-3">
              The Goods were labelled Returnable by the Seller.
            </li>
            <li className="pl-3">The Goods are in the original packaging.</li>
            <li className="pl-3">The Goods are not used.</li>
          </ul>
          <br />

          <p>The following Goods cannot be returned:</p>
          <ul>
            <li className="pl-3">
              The supply of Goods made to your specifications or clearly
              personalized.
            </li>
            <li className="pl-3">
              The supply of Goods which according to their nature are not
              suitable to be returned, deteriorate rapidly or where the date of
              expiry is over.
            </li>
            <li className="pl-3">
              The supply of Goods which are not suitable for return due to
              health protection or hygiene reasons and were unsealed after
              delivery.
            </li>
            <li className="pl-3">
              The supply of Goods which are, after delivery, according to their
              nature, inseparably mixed with other items.
            </li>
            <li className="pl-3">
              The Goods are not labelled Returnable by the Seller.
            </li>
          </ul>
          <br />
          <p>
            The seller reserves the right to refuse returns of any merchandise
            that does not meet the above return conditions in our sole
            discretion.
          </p>

          <h5 className="h4 text-bold pt-2">Contact Us</h5>
          <hr />
          <p>
            If you have any questions about this Returns and Refunds Policy,
            click on the link below. https://rosmallonline.com/return_policy
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Refund;
