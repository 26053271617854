import axios from "axios";
import { useState } from "react";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import { useLocation, useNavigate } from "react-router-dom";

const Review = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [rate, setRate] = useState("");
  const [comment, setComment] = useState("");

  // @ desc - a function to handle the submit of the form for review
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_Base_url}/company/add_review_ratings/`,
        {
          company_uuid: location.state.id,
          comment,
          rate,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        alert(response.data.message);
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="page-holder py-3">
      <Header />

      <div className="container py-5">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Note:</label>
            <span className="pl-5 pb-3">
              Please take a minute to review your experience shopping with
            </span>
          </div>

          <div className="form-group">
            <p>How would you rate the Company</p>
            &nbsp;{" "}
            <input
              type="radio"
              id="1 star"
              name="fav_language"
              value={1}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="html">
              1 star{" "}
              <span className="px-4">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-4">(Poor)</span>
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="2 stars"
              name="fav_language"
              value={2}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="css">
              2 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-4">(Weak)</span>{" "}
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="3 stars"
              name="fav_language"
              value={3}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="javascript">
              3 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-4">(Good)</span>{" "}
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="4 stars"
              name="fav_language"
              value={4}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="javascript">
              4 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-2">(Very Good)</span>{" "}
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="5 stars"
              name="fav_language"
              value={5}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="html">
              5 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-2">(Excellent)</span>{" "}
            </label>
            <br />
          </div>
          <br />

          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              Write a comment here
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              defaultValue={""}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <input
            className="btn btn-primary"
            type="submit"
            defaultValue="Submit"
          />
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Review;
