import axios from "axios";
import "./updateProfile.css";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import ChangePassword from "../../password/change password/changePassword";

const UpdateProfile = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let isMounted = false;
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_Base_url}/shopper/view_shopper_details/`,
          {
            headers: {
              "X-SHOPPER-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (!isMounted) {
            setUser(res.data.shopper_details);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      isMounted = true;
    };
  }, [token]);

  const submitHandler = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${process.env.REACT_APP_Base_url}/shopper/edit_shopper_account/`,
        {
          first_name: user.first_name,
          middle_name: "",
          last_name: user.last_name,
          country: "9ceef736-0b1a-4fc6-8db8-a3cff15f76db",
          email: user.email,
          gps_number: user.gps,
          address: "",
          location: "",
          city: "",
          postcode: "",
          dob: "",
          street: "",
          region_id: "87389a81-767c-4a61-8e4e-2e9acdf72f99",
          phone: user.phone,
          gender: user.gender,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.status === 201) {
          toast.success(
            response.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
          setTimeout(() => {
            window.location.reload(true);
          }, 4000);
        } else {
          toast.success(
            response.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
          setTimeout(() => {
            window.location.reload(true);
          }, 4000);
        }
      });
  };

  return (
    <>
      <ToastContainer />

      <div className="page-holder">
        {/* <ProfileHeader /> */}
        <Header />

        <div className="container pb-5">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <div className="my-4">
                <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="false">
                      Update Profile
                    </a>
                  </li>
                </ul>

                {user && (
                  <div className="col-lg-12">
                    <form onSubmit={submitHandler}>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="first_name">
                            First name
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="first_name"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, first_name: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.first_name}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="last_name">
                            Last name
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="last_name"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, last_name: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.last_name}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="email">
                            Email address
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="email"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, email: e.target.value };
                              })
                            }
                            type="email"
                            placeholder={user.email}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="gps_number">
                            GPS Number
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="gps_number"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, gps: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.gps}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="phone">
                            Phone
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="phone"
                            type="tel"
                            placeholder={user.phone}
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, phone: e.target.value };
                              })
                            }
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <div className="form-group">
                            <label>Gender</label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setUser((user) => {
                                  return { ...user, gender: e.target.value };
                                })
                              }>
                              <option>---</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-12 form-group">
                          <button
                            className="btn btn-primary update_profile_btn"
                            type="submit">
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                <section className="py-5">
                  {/* <p className="py-3">To change password</p> */}
                  <hr />
                  <ChangePassword />
                </section>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default UpdateProfile;
