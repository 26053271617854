import { Link } from "react-router-dom";
import Styling from "./erroPage.module.css";
import A from "../../assets/Banners/705.png";

const ErrorPage = () => {
  return (
    <div>
      <div className={Styling.container}>
        <div className="text-center">
          <img src={A} alt="baby" width={350} height={350} />
          <h3>Sorry !!! </h3>
          <h6> page not found </h6>
          {/* <h6>you don't have access to the page </h6> */}
          <Link to="/login" style={{ fontSize: "16px" }}>
            click here to go back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
