import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Rating } from "primereact/rating";

const ProductReviews = (props) => {
  // console.log(props);
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    if (props) {
      const tk = sessionStorage.getItem("token");
      setToken(tk);
      setId(props.product.productDetails.company.company_uuid);
    }
  }, [props, props.product.productDetails.company.company_uuid]);

  useEffect(() => {
    let isMounted = false;
    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/list_company_reviews/`,
          {
            company_id: id,
            record_number: "",
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((res) => {
          // console.log(res.data.reviews);
          if (!isMounted) {
            setDetails(res.data.reviews.slice(0, 4));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      isMounted = true;
    };
  }, [id, token]);

  return (
    <div>
      <div className="p-lg-2 bg-white">
        <div className="py-3">
          <label className="text-uppercase text-center">
            review of Company's performance
          </label>{" "}
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-8">
            {details &&
              details.map((detail) => {
                return (
                  <div className="media mb-3" key={detail?.review_id}>
                    <img
                      className="rounded-circle"
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                      alt=".."
                      width={50}
                      loading="lazy"
                    />
                    <div className="media-body ml-3">
                      <h6 className="mb-0 text-uppercase">
                        {detail?.shopper.first_name}
                      </h6>
                      <p className="small text-muted mb-0 text-uppercase">
                        {detail?.date_created}
                      </p>
                      <p
                        className="small text-muted mb-0 text-uppercase text-warning"
                        style={{ color: "yellow" }}>
                        <Rating
                          value={detail?.rating}
                          readOnly
                          cancel={false}
                        />
                      </p>

                      <p className="text-small mb-0 text-muted">
                        {detail?.comment}
                      </p>
                    </div>
                  </div>
                );
              })}

            <div className="ml-auto">
              <Link
                to="/company"
                state={{
                  data: props.product.productDetails.company,
                }}
                className="">
                Read more ...
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductReviews;
