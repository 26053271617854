import axios from "axios";
import { useState } from "react";
import Forgot from "./forgotPassword.module.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const SubmitPassword = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_Base_url}/shopper/forgot_password/`, {
        email,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 400) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        } else {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 6000);
        }
      })
      .catch((err) => {
        toast.warn(
          "Account Doesnt Exist or Is Inactive",
          {
            position: toast.POSITION.TOP_LEFT,
          },
          15000
        );
      });
  };

  return (
    <>
      <ToastContainer />

      <div className="container py-5">
        <form className={Forgot.center} onSubmit={SubmitPassword}>
          <h2 className="text-center py-5">FORGOT PASSWORD</h2>

          <div className="card">
            <div className="card-body">
              <p className="card-text py-3 text-center">
                Enter the email address associated with your account and we will
                send you a link to reset your password.
              </p>

              <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col">
                  <InputText
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <br />
                <div className="p-field p-col">
                  <Button label="Submit" />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            <p>
              Dont have an account yet ? <a href="/register">Register</a>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
