import axios from "axios";
import { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { ToastContainer, toast } from "react-toastify";

const ChangePassword = () => {
  const [visible, setVisible] = useState(false);
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // @desc - function to handle the change password
  const settingsHandler = (e) => {
    e.preventDefault();

    if (new_password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/change_password/`,
          {
            new_password,
            old_password,
          },
          {
            headers: {
              "X-SHOPPER-USER-AUTH": sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          toast.success(
            res.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
          if (res.data.status === 200) {
            // clear token and move to the home page
            setTimeout(() => {
              sessionStorage.clear();
              window.location.href = "/";
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="card flex justify-content-center">
        <Button
          label="click to change password"
          icon="pi pi-external-link"
          onClick={() => setVisible(true)}
        />
        <Dialog
          header="Change Password"
          visible={visible}
          maximizable
          style={{ width: "60vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setVisible(false)}>
          <p className="m-0">
            <form className="form-horizontal" onSubmit={settingsHandler}>
              <div className="card">
                <div className="card-body">
                  <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                      <h5>Current Password</h5>
                      <Password
                        required
                        value={old_password}
                        onChange={(e) => setOldPassword(e.target.value)}
                        toggleMask
                      />
                    </div>
                    <br />
                    <div className="p-field p-col">
                      <h5>New Password</h5>
                      <Password
                        required
                        value={new_password}
                        onChange={(e) => setNewPassword(e.target.value)}
                        toggleMask
                      />
                    </div>
                    <br />
                    <div className="p-field p-col">
                      <h5>Confirm Password</h5>
                      <Password
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        toggleMask
                      />
                    </div>
                    <br />
                    <div className="p-field p-col">
                      <Button label="Submit" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </p>
        </Dialog>
      </div>
    </>
  );
};

export default ChangePassword;
