import Styles from "./search.module.css";
import { useState, useEffect } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useLocation } from "react-router-dom";

const DisplaySearch = () => {
  const location = useLocation();
  const [list, setList] = useState([]);

  useEffect(() => {
    if (location.state) {
      setList(location.state.data);
    }
  }, [location.state]);

  return (
    <>
      <Header />

      <div className="container py-3" id={Styles.displayHeader}>
        <div className="row blog">
          {list.map((items) => {
            return (
              <div
                className="col-lg-2 col-md-3 col-6 p-1"
                key={items.product_uuid}>
                <div className="product">
                  <div className="mb-3 position-relative">
                    {items?.discount === "0.00" ? null : (
                      <div className="badge text-white badge-danger">
                        {items?.discount}%
                      </div>
                    )}

                    <Link
                      className="d-block"
                      to={`/product/details?items_id=${items?.product_uuid}`}>
                      <img
                        src={items?.image_url}
                        className={Styles.pro}
                        alt="Image1"
                        loading="lazy"
                      />
                    </Link>
                    <div className="product-overlay"></div>
                  </div>

                  <h6 className="mb-0" id={Styles.display_h6}>
                    <Link
                      className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                      to={`/product/details?items_id=${items?.product_uuid}`}>
                      {items?.product_name.length < 21
                        ? `${items?.product_name}`
                        : `${items?.product_name.substring(0, 18)}...`}
                    </Link>
                  </h6>

                  <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                    <span className="px-1">
                      {items?.discount === "0.00"
                        ? `GH₵ ${""} ${items?.new_price}`
                        : `GH₵ ${""} ${items?.new_price}`}
                    </span>

                    {items?.discount !== "0.00" ? (
                      <del className="px-1" style={{ color: "red" }}>
                        GH₵ {""} {items?.old_price}
                      </del>
                    ) : null}
                  </p>
                  <Link
                    id={Styles.display_company}
                    to={`/company?company_id=${items?.company_uuid}`}
                    className="text-decoration-none px-1 d-flex justify-content-start">
                    {items?.company.company_name.length < 20
                      ? `${items?.company.company_name}`
                      : `${items?.company.company_name.substring(0, 19)}..`}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DisplaySearch;
