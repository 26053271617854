import BanStyles from "./banners.module.css";
import B from "../../assets/Banners/sss4.png";
import C from "../../assets/Banners/cedi.png";
import Ad from "../../assets/Banners/sss5.png";
import Add from "../../assets/Banners/sss1.png";

const Banners = () => {
  return (
    <>
      <section className="py-1">
        <div className="row text-center">
          <div className="col-3">
            <div className="d-inline-block">
              <div className="media align-items-end">
                <div className="media-body">
                  <img
                    src={Add}
                    className={BanStyles.baner}
                    alt="..."
                    loading="lazy"
                  />
                  <br />
                  <h6
                    id={BanStyles.ban_h6}
                    className="text-uppercase text-center py-2">
                    Top Rated Sellers
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="d-inline-block">
              <div className="media align-items-end">
                <div className="media-body">
                  <img
                    src={Ad}
                    className={BanStyles.baner}
                    alt="..."
                    loading="lazy"
                  />
                  <br />
                  <h6
                    id={BanStyles.ban_h6}
                    className="text-uppercase text-center py-2">
                    Super Deals
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="d-inline-block">
              <div className="media align-items-end">
                <div className="media-body">
                  <img
                    src={B}
                    className={BanStyles.baner}
                    alt="..."
                    loading="lazy"
                  />
                  <br />
                  <h6
                    id={BanStyles.ban_h6}
                    className="text-uppercase text-center py-2">
                    Quality service Assured{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="d-inline-block">
              <div className="media align-items-end">
                <div className="media-body">
                  <img
                    src={C}
                    className={BanStyles.baner}
                    alt="..."
                    loading="lazy"
                  />
                  <br />
                  <h6
                    id={BanStyles.ban_h6}
                    className="text-uppercase text-center py-2">
                    Money Back Gurranted
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banners;
