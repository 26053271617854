import ProductFilter from "../../filter/productFilter";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReactPagination from "react-paginate";
import { useState, useEffect } from "react";
import ProStyle from "./product.module.css";
import queryString from "query-string";
import {
  updateSubCategories,
  updateProductsCategory,
} from "../../../redux/slice/shopperSlice";
import axios from "axios";

const Products = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(0);
  const { subCategories, productCategory } = useSelector(
    (state) => state.shopper
  );
  const { search } = useLocation();
  const { cat_id } = queryString.parse(search);

  useEffect(() => {
    let mounted = false;

    if (cat_id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/setup/list_product_sub_category/`,
          {
            product_cat_uuid: cat_id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((resp) => {
          if (!mounted && resp.data?.status === 200) {
            // the below line is the one that updates the state
            dispatch(updateSubCategories(resp.data.product_sub_category));
          }
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/get_product_posts/`,
          {
            product_cat_id: cat_id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((res) => {
          if (!mounted && res.data.message === "Record(s) Not Found") {
            updateProductsCategory([]);
            toast.warn(
              "No Product(s) Found",
              {
                position: toast.POSITION.TOP_RIGHT,
              },
              { autoClose: 10000 }
            );
          } else {
            if (!mounted && res.data?.status === 200) {
              // the below line is the one that updates the state
              dispatch(updateProductsCategory(res.data.product_posts));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // a cleanup function to set mounted to true to avoid setting state on an unmounted component
    return () => {
      mounted = true;
    };
  }, [cat_id, dispatch]);

  const usersPerPage = 24;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = productCategory
    ? Math.ceil(productCategory.length / usersPerPage)
    : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <ToastContainer />
      <div className="page-holder">
        <Header />

        <div className="container">
          <section className="py-5">
            <div className="container p-0">
              <div className="row">
                {/* SHOP SIDEBAR*/}
                <div className="col-lg-2 order-2 order-lg-1">
                  <h5 className="text-uppercase mb-4">Categories</h5>
                  <div className="py-2 px-4 bg-dark text-white mb-3">
                    <strong className="small text-uppercase font-weight-bold">
                      Products
                    </strong>
                  </div>
                  <ul className="list-unstyled small text-muted font-weight-normal bg-light">
                    {subCategories &&
                      subCategories.map((item) => {
                        return (
                          <li
                            className="mb-0"
                            key={item.product_sub_category_id}>
                            <Link
                              className="dropdown-item"
                              to={`${
                                location.pathname
                              }/${item.sub_category_name.toLowerCase()}?sub_cat_id=${
                                item.product_sub_category_id
                              }`}>
                              {item.sub_category_name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>

                  <div className="price-range pt-4 mb-5">
                    {/* yet to work on it */}
                    <ProductFilter />
                  </div>
                </div>
                {/* SHOP LISTING*/}
                <div className="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">
                  <div className="row mb-3 align-items-center" id="sort">
                    <div className="col-lg-6 mb-2 mb-lg-0">
                      {productCategory && (
                        <p className="text-small text-muted mb-0">
                          Total items: {productCategory.length}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {productCategory &&
                      productCategory
                        .slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((electronic) => (
                          <div
                            className="col-lg-3 col-md-3 col-6 p-1"
                            key={electronic?.product_uuid}>
                            <div className="card">
                              <div className="product pb-2">
                                <div className="mb-3 position-relative">
                                  {electronic?.discount === "0.00" ? null : (
                                    <div className="badge text-white badge-danger">
                                      {electronic?.discount}%
                                    </div>
                                  )}
                                  <Link
                                    className="d-block"
                                    to={`/product/details?items_id=${electronic?.product_uuid}`}
                                    // state={{ data: electronic }}
                                  >
                                    <img
                                      className={ProStyle.pro}
                                      src={electronic?.image_url}
                                      alt="..."
                                      loading="lazy"
                                    />
                                  </Link>
                                  <div className="product-overlay"></div>
                                </div>

                                <h6 className="mb-0">
                                  <Link
                                    style={{ fontSize: "14px" }}
                                    className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                                    to={`/product/details?items_id=${electronic?.product_uuid}`}
                                    state={{ data: electronic }}>
                                    {electronic?.product_name.length < 23
                                      ? `${electronic?.product_name}`
                                      : `${electronic?.product_name.substring(
                                          0,
                                          19
                                        )}..`}
                                  </Link>
                                </h6>

                                <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                                  {/* am conditionally rendering the discount price if not display the real price */}
                                  <span className="px-1">
                                    GH₵ {electronic?.new_price}
                                  </span>

                                  {electronic?.discount !== "0.00" ? (
                                    <del
                                      className="px-2"
                                      style={{ color: "red" }}>
                                      GH₵ {electronic?.old_price}
                                    </del>
                                  ) : null}
                                </p>

                                <Link
                                  to={`/company?company_id=${electronic?.company.company_uuid}`}
                                  // to="/company"
                                  // state={{
                                  //   data: electronic?.company,
                                  // }}
                                  className="text-decoration-none px-1 d-flex justify-content-start">
                                  {electronic?.company.company_name.length < 20
                                    ? `${electronic?.company.company_name}`
                                    : `${electronic?.company.company_name.substring(
                                        0,
                                        19
                                      )}..`}
                                </Link>
                                {/* <hr /> */}
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                  {productCategory && (
                    <div className="container">
                      <ReactPagination
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        breakLabel={"..."}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Products;
