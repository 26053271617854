import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";

const About = () => {
  return (
    <>
      <Header />
      <div className="container px-1">
        <div className="container text-left px-3 py-2 m-2">
          <h2 className="h4 text-bold pt-2">What We Do</h2>
          <p>
            We are an e-commerce website focused on making online shopping
            secure, fun, and convenient for all. We provide an online shopping
            platform for both small and medium businesses to sell their products
            and services, and for shoppers to purchase from the comfort of their
            homes or offices and have it delivered.
          </p>

          <h2 className="h4 text-bold">Mission</h2>
          <p>
            To make online selling and buying easily accessible to shoppers and
            small and medium businesses across Ghana.
          </p>

          <h2 className="h4 text-bold">Vision</h2>
          <p>
            To provide a reliable and superior shopping experience for our
            customers.
          </p>

          <h2 className="h4 text-bold pt-2">Values</h2>
          <p>
            We are keen on providing tailored services to our clients to ensure
            customer satisfaction with the best delivery.
          </p>

          <h2 className="h4 text-bold pt-2">Brand Personality</h2>
          <p>Sell Easy Buy Easy</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
