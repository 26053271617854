import axios from "axios";
import { useState } from "react";
import Logino from "./login.module.css";
import { FaUserAlt } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const [data, setData] = useState({ email: "", password: "" });

  // @desc - This function is used to submit the login form
  const submitHandler = async (e) => {
    e.preventDefault();

    if (data.email !== "" && data.password !== "") {
      axios
        .post(`${process.env.REACT_APP_Base_url}/shopper/shopper_login/`, data)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            sessionStorage.setItem("token", res.data.token);
            if (location?.state === null) {
              navigate("/");
            } else if (location?.state.location) {
              navigate(location.state.location.pathname, {
                state: location.state.location.state,
              });
            } else if (location?.state.from) {
              navigate(location.state.from);
            } else if (location?.state?.from?.pathname) {
              navigate(location.state.from.pathname);
            } else {
              navigate("/");
            }
          } else {
            // console.log("Redirect failed");
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <ToastContainer />

      <div className={Logino.main}>
        <div className={Logino.box}>
          <section>
            <a
              className="float-right btn btn-small btn-outline-primary"
              href="/register"
              style={{ backgroundColor: "#0e8ce4", color: "white" }}>
              Register Here
            </a>
            <h4 className="card-title mb-2 mt-1 py-1 text-uppercase">
              Sign in
            </h4>
            <hr />

            <form onSubmit={submitHandler}>
              <div className="px-2 pt-3 pb-2">
                <div className="form-group py-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FaUserAlt />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group py-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <AiFillLock />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      placeholder="******"
                      type="password"
                      name="password"
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  style={{
                    backgroundColor: "#0e8ce4",
                    color: "white",
                  }}>
                  Login
                </button>
              </div>
              <div className={Logino.forgot}>
                <p>
                  <a href="/forgot_password" className="btn m-1">
                    Forgot password ?
                  </a>
                </p>
                <p>
                  <a
                    href="https://rosmallonline.com:7000/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn m-1">
                    Login as a company
                  </a>
                </p>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default Login;
