import axios from "axios";
import "./mobileSidebar.css";
import Search from "../search/search";
import Hero from "./header.module.css";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import React, { useState, useEffect } from "react";
import A from "../../assets/Banners/rosmall logo.png";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux/slice/shopperSlice";
import { useFetchWithoutToken } from "../../hooks/customHooks";
import { BsFillTelephoneFill, BsCartCheckFill } from "react-icons/bs";

const Header = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const { shopper_details } = useSelector((state) => state.shopper);

  const endSession = () => {
    // Remove all saved data from sessionStorage
    sessionStorage.clear();
  };

  //   To get the product list
  const [response] = useFetchWithoutToken("/setup/list_product_category/");
  const cat = response?.product_category;

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_Base_url}/shopper/view_shopper_details/`,
          {
            headers: {
              "X-SHOPPER-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            // the below line is the one that updates the state
            dispatch(setUserInfo(res.data.shopper_details));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token, dispatch]);

  const handleSidebar = () => {
    document.querySelector(".mobile-sidebar").classList.toggle("active");
    document.querySelector(".overlay").classList.toggle("active");
  };
  const nav_DropDown = () => {
    document.querySelector(".nav-item.dropdown").classList.toggle("show");
    document.querySelector(".dropdown-menu").classList.toggle("show");
  };

  return (
    <div>
      <header className="header">
        {/* Top Bar */}
        <div className="top_bar" style={{ backgroundColor: "white" }}>
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-row">
                <div className="top_bar_contact_item">
                  <div>
                    <Link to="/">Home</Link>
                  </div>
                </div>
                <div className="top_bar_contact_item">
                  <div />
                </div>
                <div className="top_bar_contact_item pl-3">
                  <div />
                </div>
                <div className="top_bar_contact_item">
                  <div className="top_bar_icon" />
                </div>
                <div className="top_bar_content ml-auto">
                  <div className="top_bar_menu">
                    <ul className="standard_dropdown top_bar_dropdown"></ul>
                  </div>
                  {!token ? (
                    <div className="top_bar_user">
                      <div className="user_icon"></div>
                      <div>
                        <Link to="/register">Register</Link>
                      </div>
                      <div>
                        <Link to="/login">Sign in</Link>
                      </div>
                    </div>
                  ) : (
                    <div className="top_bar_user">
                      <div className="user_icon"></div>
                      <div>
                        {shopper_details && (
                          <Link to="/profile/update">
                            <span className="px-1">Welcome back</span>
                            {""}
                            {shopper_details.first_name}
                          </Link>
                        )}
                      </div>
                      <div>
                        <a href="/" onClick={endSession}>
                          Logout
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Header Main */}
        <Search />
        {/* Main Navigation */}

        <nav className="navbar navbar-expand-lg navbar-light" id={Hero.bb}>
          <section className="container">
            {/* </toggle button> */}
            <FaBars
              size={18}
              color="white"
              onClick={handleSidebar}
              id="mob_toggle"
            />
            {/* </toggle button> */}
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {/* Dropdown menu */}
                <li className="nav-item dropdown">
                  <a
                    onClick={nav_DropDown}
                    className="nav-link dropdown-toggle"
                    href="#/"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ opacity: "1.8", color: "white" }}>
                    <span className="">Products</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink">
                    {cat &&
                      cat.map((item, key) => {
                        return (
                          <div key={item.product_category_id} className="">
                            <Link
                              onClick={nav_DropDown}
                              className="dropdown-item"
                              key={key}
                              to={`/products/${item.product_category_name.toLowerCase()}?cat_id=${
                                item.product_category_id
                              }`}>
                              {item.product_category_name}
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/companies"
                    style={{ opacity: "1.8", color: "white" }}>
                    Companies
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/sell"
                    style={{ opacity: "1.8", color: "white" }}>
                    <span className="">Sell</span>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/return_policy"
                    style={{ opacity: "1.8", color: "white" }}>
                    Return Process
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/profile/shopping_history"
                    style={{ opacity: "1.8", color: "white" }}>
                    Shopping History
                  </Link>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/#"
                    id={Hero.mob}
                    style={{ opacity: "1.8", color: "white" }}>
                    <span className="mx-1">
                      <BsFillTelephoneFill />
                    </span>
                    0302971344
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id={Hero.call} href="/#">
                    <span className="mx-1">
                      <BsFillTelephoneFill />
                    </span>
                    0302971344
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </nav>
      </header>
      {/* mobile screen */}
      <>
        <div className="mobile-sidebar">
          <div className="close_btn">
            <i className="fa fa-times" onClick={handleSidebar}></i>
          </div>
          <section>
            <div className="mobile-logo">
              <Link to="/">
                <img src={A} alt="logo_not_found" className="img-fluid" />
              </Link>
            </div>
          </section>
          <section className="bottom_design">
            <div className="search_btn">
              <Link to="/search">
                <BiSearchAlt size={24} />
              </Link>
            </div>
            <div className="mobile-sidebar-body">
              <div className="mobile_head">
                <i className="fa fa-bars" aria-hidden="true"></i>
                <h4>MENU LIST</h4>
              </div>
              <ul>
                {/* <li className="mx-2">
                  <a href="/products">Products</a>
                  <h5>PRODUCTS</h5>
                </li> */}

                {cat &&
                  cat.map((item, key) => {
                    return (
                      <div key={item.product_category_id} className="mx-4">
                        <Link
                          onClick={handleSidebar}
                          className="dropdown-item"
                          key={key}
                          to={`/products/${item.product_category_name.toLowerCase()}?cat_id=${
                            item.product_category_id
                          }`}
                          // state={{ data: item.product_category_id }}
                        >
                          {item.product_category_name}
                        </Link>
                      </div>
                    );
                  })}

                {/* <li>
                  <a href="/services">Services</a>
                </li> */}
              </ul>
            </div>
          </section>
          <section className="bottom_design">
            <div className="mobile-sidebar-footer">
              <div className="mobile_head mb-2">
                <i className="fa fa-user" aria-hidden="true"></i>
                <h4>ACCOUNT</h4>
              </div>
              <ul>
                <li className="mb-2">
                  <span className="mx-3">
                    <BsCartCheckFill />
                  </span>
                  <Link to="/profile/shopping_history">Shopping History</Link>
                </li>

                <li className="mb-2">
                  <span className="mx-3">
                    <FiSettings />
                  </span>
                  <Link to="/profile/update">Profile</Link>
                </li>

                <li className="mb-2">
                  <span className="mx-3">
                    <FiLogOut />
                  </span>
                  <a href="/" onClick={endSession}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <section className="bottom_links">
            <a href="tel:0302971344" className="mobile-call">
              <span className="mx-1">
                <BsFillTelephoneFill />
              </span>
              0302971344{" "}
              <span className="mx-2">in case you need any help </span>
            </a>
          </section>
        </div>
      </>
      {/* mobile screen end */}
      <div className="overlay" onClick={handleSidebar} />
    </div>
  );
};

export default Header;
