import Footer from "../../../components/footer/footer";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../../components/header/header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import axios from "axios";

const ProfileCart = () => {
  const navigate = useNavigate();
  // get the cart list from local storage
  const { temporaryCart } = useSelector((state) => state.shopper);
  console.log(temporaryCart);

  const tk = sessionStorage.getItem("token");
  const token = tk ? tk : null;

  // @ desc - a function to handle delete cart -@ param - id
  const deleteCart = async (id) => {
    toast.error(
      "Item removed from cart",
      {
        position: toast.POSITION.TOP_RIGHT,
      },
      { autoClose: 2000 }
    );

    // get the cart list from local storage
    const cartList = await JSON.parse(localStorage.getItem("temporaryCart"));

    // filter the cart list to remove the item with the id passed
    const newCartList = await cartList.filter(
      (item) => item.product_details.product_uuid !== id
    );

    // update the cart list in local storage
    localStorage.setItem("temporaryCart", JSON.stringify(newCartList));

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  // @ desc - a function to handle checkout
  const handleCheckout = () => {
    // get the cart list from local storage
    const cartList = JSON.parse(localStorage.getItem("temporaryCart"));
    console.log(cartList);

    // check if cart list is empty
    if (cartList === null) {
      toast.warn(
        "cart is empty",
        {
          position: toast.POSITION.TOP_RIGHT,
        },
        { autoClose: 2000 }
      );
    } else {
      if (token) {
        // loop through the cart list and each time calling the add to cart api
        cartList.forEach(async (item) => {
          const data = {
            product_id: item.product_details.product_uuid,
            quantity: item.quantity,
            product_type_size: item.product_type_size,
          };

          axios
            .post(`${process.env.REACT_APP_Base_url}/cart/add_to_cart/`, data, {
              headers: {
                "X-SHOPPER-USER-AUTH": token,
              },
            })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        });
        // clear local storage
        localStorage.clear();

        window.location.href = "/checkout";
      } else {
        // window.location.href = "/login";
        navigate("/login", {
          state: { from: "/profile/cart" },
        });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="page-holder">
        {/* <ProfileHeader /> */}
        <Header />
        {/* End of Header */}
        {/* CATEGORIES SECTION*/}
        <section className="container py-5">
          <div className="row">
            <div className="col-lg-12 mb-4 mb-lg-0">
              {/* CART TABLE*/}
              <div className="table-responsive mb-4">
                <table className="table">
                  <thead className="bg-light">
                    <tr>
                      <th className="border-0" scope="col">
                        {" "}
                        <strong className="text-small text-uppercase">
                          Product
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        {" "}
                        <strong className="text-small text-uppercase">
                          Quantity
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        {" "}
                        <strong className="text-small text-uppercase">
                          Size
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        {" "}
                        <strong className="text-small text-uppercase">
                          Total
                        </strong>
                      </th>

                      <th className="border-0" scope="col">
                        {" "}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {temporaryCart &&
                      temporaryCart.map((cart) => {
                        return (
                          <tr key={cart.product_details.product_uuid}>
                            <th className="pl-0 border-0" scope="row">
                              <div className="media align-items-center">
                                <a
                                  className="reset-anchor d-block animsition-link"
                                  href="/">
                                  <img
                                    src={cart.product_details.image_url}
                                    alt="..."
                                    width={70}
                                    loading="lazy"
                                  />
                                </a>
                                <div className="media-body ml-3">
                                  <strong className="h6">
                                    <Link
                                      className="reset-anchor animsition-link"
                                      to={`/product/details?items_id=${cart?.product_details.product_uuid}`}>
                                      {" "}
                                      {cart.product_details.product_name}{" "}
                                    </Link>
                                  </strong>
                                </div>
                              </div>
                            </th>

                            <td className="align-middle border-0">
                              <div className="quantity">
                                <p className="form-control form-control-sm border-0 shadow-0 p-3">
                                  {" "}
                                  {cart.quantity}{" "}
                                </p>
                              </div>
                            </td>

                            {cart.product_type_size !== "" ? (
                              <td className="align-middle border-0">
                                <div className="quantity">
                                  <p className="form-control form-control-sm border-0 shadow-0 p-3">
                                    {" "}
                                    {cart.product_type_size}{" "}
                                  </p>
                                </div>
                              </td>
                            ) : (
                              <td className="align-middle border-0">
                                <div className="quantity">
                                  <p className="form-control form-control-sm border-0 shadow-0 p-3">
                                    {" "}
                                    N/A{" "}
                                  </p>
                                </div>
                              </td>
                            )}

                            <td className="align-middle border-0">
                              <p className="mb-0 small">
                                GH₵ {cart.total_price}
                              </p>
                            </td>
                            <td className="align-middle border-0">
                              <span
                                className="reset-anchor"
                                onClick={() =>
                                  deleteCart(cart.product_details.product_uuid)
                                }>
                                <TiDelete size={24} color="red" />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {/* CART NAV*/}
              <div className="bg-light px-4 py-3">
                <div className="row align-items-center text-center">
                  <div className="col-md-6 mb-3 mb-md-0 text-md-left">
                    <a className="btn btn-link p-0 text-dark btn-sm" href="/">
                      <i className="fas fa-long-arrow-alt-left mr-2"> </i>
                      Continue shopping
                    </a>
                  </div>
                  <div className="col-md-6 text-md-right">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleCheckout}
                      type="button">
                      Proceed to checkout
                      <i className="fas fa-long-arrow-alt-right ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ORDER TOTAL*/}
          </div>
        </section>
        {/*END OF CATEGORIES SECTION*/}

        <Footer />
      </div>
    </>
  );
};

export default ProfileCart;
