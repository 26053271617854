import axios from "axios";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";

const ShoppingHistory = () => {
  const [token, setToken] = useState(null);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    let isMounted = false;
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/cart/shopping_history/`, {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        })
        .then((response) => {
          if (!isMounted) {
            setHistory(response.data.shopper_shopping_history);
          }
          if (response.data.status === 400) {
            toast.warn(
              response.data.message,
              {
                position: toast.POSITION.TOP_RIGHT,
              },
              { autoClose: 10000 }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error);
        });
    }
    return () => {
      isMounted = true;
    };
  }, [token]);

  // @ desc - a function to handle payout
  // @ param - id
  const payOut = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/payment/payout/`,
        {
          cart_id: id,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);

        toast.success(
          res.data.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          },
          { autoClose: 10000 }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setMessage = () => {
    alert("Will be contacted soon");
  };

  return (
    <>
      <ToastContainer />

      <div className="page-holder">
        {/* <ProfileHeader /> */}
        <Header />

        {/* CATEGORIES SECTION*/}
        <section className="container py-5">
          <div className="row">
            <div className="col-lg-12 mb-4 mb-lg-0">
              {/* CART TABLE*/}
              <div className="table-responsive mb-4">
                <table className="table">
                  <thead className="bg-light">
                    <tr>
                      <th className="border-0" scope="col">
                        <strong className="text-small text-uppercase">
                          Product
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        <strong className="text-small text-uppercase">
                          Review
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        <strong className="text-small text-uppercase">
                          Return
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        <strong className="text-small text-uppercase">
                          Payout
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        <strong className="text-small text-uppercase">
                          Quantity
                        </strong>
                      </th>
                      <th className="border-0" scope="col">
                        Total
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {history &&
                      history.map((historyItem) => {
                        return (
                          <tr key={historyItem.cart_uuid}>
                            <th className="pl-0 border-0" scope="row">
                              <div className="media-body ml-3">
                                <strong className="h6">
                                  <a
                                    className="reset-anchor animsition-link"
                                    href="#/">
                                    {historyItem.product.product_name}
                                  </a>
                                </strong>
                              </div>
                            </th>
                            <td className="align-middle border-0">
                              <p className="mb-0 small">
                                <Link
                                  to="/review"
                                  state={{
                                    id: historyItem.product.company
                                      .company_uuid,
                                  }}
                                  className="btn btn-primary">
                                  Review
                                </Link>
                              </p>
                            </td>
                            <td className="align-middle border-0">
                              <p className="mb-0 small">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setMessage()}>
                                  Return
                                </button>
                                {/* <Link
                                to={{
                                  pathname: "/return",
                                  state: { data: historyItem.product },
                                }}
                                className="btn btn-primary">
                                Return
                              </Link> */}
                              </p>
                            </td>

                            <td className="align-middle border-0">
                              {historyItem.status === "PAYMENT COMPLETED" ? (
                                <p className="mb-0 small">
                                  <button
                                    onClick={() =>
                                      payOut(historyItem.cart_uuid)
                                    }
                                    type="button"
                                    className="btn btn-success">
                                    Done
                                  </button>
                                </p>
                              ) : (
                                <p className="mb-0 small">
                                  <button
                                    onClick={() =>
                                      payOut(historyItem.cart_uuid)
                                    }
                                    type="button"
                                    className="btn btn-primary">
                                    Payout
                                  </button>
                                </p>
                              )}
                            </td>
                            <td className="align-middle border-0">
                              <p>{historyItem.quantity}</p>
                            </td>
                            <td className="align-middle border-0">
                              <p className="mb-0 small">
                                GH₵ {historyItem.total}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        {/*END OF CATEGORIES SECTION*/}

        <Footer />
      </div>
    </>
  );
};

export default ShoppingHistory;
