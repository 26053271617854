import "./register.css";
import axios from "axios";
import { useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { ToastContainer, toast } from "react-toastify";

const RegisterShopper = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [gps_number, setGpsNumber] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  // @desc   This is the function that handles the registration of a shopper
  // some parts of the register data are hard coded because it is a required field for registration 👇
  //  however it is done to simplify the registration process
  const submitHandler = async (e) => {
    e.preventDefault();

    if (password === confirm_password) {
      await axios
        .post(`${process.env.REACT_APP_Base_url}/shopper/add_shopper/`, {
          first_name,
          middle_name: "",
          last_name,
          country: "9ceef736-0b1a-4fc6-8db8-a3cff15f76db",
          email,
          password,
          gps_number,
          address: "",
          location: "",
          city: "",
          postcode: "",
          dob: "",
          street: "",
          region_id: "87389a81-767c-4a61-8e4e-2e9acdf72f99",
          phone,
          gender,
        })
        .then((response) => {
          console.log(response);
          toast.success(
            response.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
          if (response.data.status === 201) {
            setTimeout(() => {
              window.location.replace("/login");
            }, 4000);
          }
        });
    } else {
      toast.error(
        "Password and confirm password do not match",
        {
          position: toast.POSITION.TOP_RIGHT,
        },
        { autoClose: 10000 }
      );
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="page-holder">
        <Header />

        <div className="container register_head">
          <section className="content">
            <h2 className="h5 text-uppercase mb-4">
              Registration Page details
            </h2>
            <div className="row">
              <div className="container">
                <div className="container">
                  <div className="col-lg-12">
                    <form onSubmit={submitHandler}>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="first_name">
                            First name
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="first_name"
                            required
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            placeholder="Enter your first name"
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="last_name">
                            Last name
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="last_name"
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                            required
                            placeholder="Enter your last name"
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <div className="form-group">
                            <label>Gender</label>
                            <select
                              className="form-control"
                              required
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}>
                              <option>---</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="email">
                            Email address
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            required
                            placeholder="e.g. jason@example.com"
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="gps_number">
                            GPS Number
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="gps_number"
                            onChange={(e) => setGpsNumber(e.target.value)}
                            type="text"
                            placeholder="gps_number"
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="phone">
                            Phone
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            type="tel"
                            minLength={10}
                            maxLength={10}
                            required
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="password">
                            Password
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            placeholder="**********"
                            required
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="Confirm password">
                            Confirm Password
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="Confirm password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type="password"
                            placeholder="**********"
                            required
                          />
                        </div>

                        <div className="col-lg-12 form-group">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            id="register_btn">
                            Register
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default RegisterShopper;
