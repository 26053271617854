import { FaCartArrowDown, FaUserAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import A from "../../assets/Banners/rosmall logo.png";
import { Link, useNavigate } from "react-router-dom";
import Styles from "./search.module.css";
import { useState } from "react";
import axios from "axios";

const Search = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [record, setRecord] = useState("");

  // @desc: this is to get the temporary cart count
  const tmp_cart_count = JSON.parse(localStorage.getItem("temporaryCart"));

  // @desc: this function handles the search
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/advance_search/`,
        {
          product_name: name,
          record_name: record,
          offset: "0",
          record_number: "50",
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.data.message === "Record(s) Not Found") {
          toast.warn(
            res.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
        } else {
          toast.success(
            res.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
          navigate("/search/results", {
            state: { data: res.data.products },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <ToastContainer />

      <div className="header_main" style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            {/* Logo */}
            <div className="col-lg-4 col-sm-3 col-3 order-1">
              <div className="logo_container">
                <div className="logo">
                  <Link to="/">
                    <img src={A} alt="logo_" id={Styles.ros_logo} />
                  </Link>
                </div>
              </div>
            </div>{" "}
            {/* Search */}
            <div className="col-lg-6 col-12 order-lg-2 order-3 text-lg-left text-right">
              <div className="header_search">
                <div className="header_search_content">
                  <div className="header_search_form_container">
                    <form
                      onSubmit={handleSubmit}
                      className="header_search_form clearfix">
                      <input
                        type="search"
                        className="header_search_input"
                        placeholder="Search for products..."
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div
                        className="custom_dropdown"
                        style={{ display: "block" }}>
                        <div className="custom_dropdown_list">
                          <select
                            className="custom_dropdown_placeholder"
                            style={{ backgroundColor: "white" }}
                            id="gat"
                            required
                            onChange={(e) => setRecord(e.target.value)}>
                            <option value=""> --- category --- </option>
                            <option
                              value="product"
                              style={{ fontSize: "18px" }}>
                              Product
                            </option>
                          </select>
                          <i className="fas fa-chevron-down mx-2" />
                        </div>
                      </div>{" "}
                      <button
                        type="submit"
                        className="header_search_button trans_300"
                        value="Submit">
                        <img
                          src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560918770/search.png"
                          alt=""
                          loading="lazy"
                        />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Wishlist */}
            <div className="col-lg-2 col-9 order-lg-3 order-2 text-lg-left text-right">
              <div className="wishlist_cart d-flex flex-row align-items-center justify-content-end">
                <div className="cart">
                  <div className="cart_container d-flex flex-row align-items-center justify-content-end">
                    <div className="cart_icon">
                      <Link to="/profile/cart" style={{ color: "#0e8ce4" }}>
                        <FaCartArrowDown className="mob_size" />
                      </Link>
                      {tmp_cart_count && (
                        <div className="cart_count">
                          <span>{tmp_cart_count?.length}</span>
                        </div>
                      )}
                    </div>
                    <div className="cart_content px-2">
                      <div className="cart_icon">
                        <Link to="/profile/update" style={{ color: "#0e8ce4" }}>
                          {" "}
                          <FaUserAlt className="mob_size" />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
