import axios from "axios";
import "./activation.css";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { ToastContainer, toast } from "react-toastify";

const ActivationPage = () => {
  const { search } = useLocation();
  const [display, setDisplay] = useState("");
  const { email, profile_id, token } = queryString.parse(search);

  useEffect(() => {
    let isMounted = false;
    if (email && profile_id && token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/activate_shopper_account/`,
          {
            acct_uuid: profile_id,
            email,
            token,
          }
        )
        .then((res) => {
          if (!isMounted) {
            setDisplay(res.data.message);

            toast.success(
              res.data.message,
              {
                position: toast.POSITION.TOP_RIGHT,
              },
              { autoClose: 10000 }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      isMounted = true;
    };
  }, [email, profile_id, token]);

  // @ desc - a function to handle resend activation link
  const handleResend = () => {
    if (email && profile_id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/resend_activation_link/`,
          {
            acct_uuid: profile_id,
            email,
          }
        )
        .then((res) => {
          toast.success(
            res.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("error occurred");
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="page-holder">
        <Header />

        <div className="container py-5" style={{ height: "60vh" }}>
          <h1 className="text-center" id="account_activation">
            Account Activation{" "}
          </h1>
          <hr />

          {display &&
          display ===
            "Link Has Expired. Kindly Click On The Button Below to Resend Activation Link To Your Email" ? (
            <p className="text-center py-3 text-danger">
              <b> {display}</b>
            </p>
          ) : (
            <p className="text-center py-3 text-success">
              <b> {display}</b>
            </p>
          )}

          {display ===
            "Link Has Expired. Kindly Click On The Button Below to Resend Activation Link To Your Email" && (
            <div className="text-center py-3">
              <button className="btn btn-primary" onClick={handleResend}>
                Resend Verification Email
              </button>
            </div>
          )}

          {display === "Account Activated Successfully" && (
            <p className="text-center py-3">
              Your account with email address <b>{email}</b> has been
              successfully activated.
            </p>
          )}

          {display === "Profile Does Not Exist" && (
            <div className="text-center py-3">
              <a href="/register" className="btn btn-primary">
                Register
              </a>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ActivationPage;
