import { MdOutlineDeleteForever } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";

const CartAlert = () => {
  const [visible, setVisible] = useState(false);
  const [cart_items, setCartItems] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get(`${process.env.REACT_APP_Base_url}/cart/shopper_cart/`, {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          // the below line is the one that updates the state
          setCartItems(response.data.shopper_cart_data);
        }
      });
  }, [token]);

  // @ desc - a function to handle delete cart -@ param - id
  const deleteCart = async (id) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/cart/delete_cart_item/`,
        {
          cart_ids: [id],
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.status === 200) {
          toast.error(
            resp.data.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 2000 }
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
  };

  return (
    <>
      <ToastContainer />

      <div className="card flex justify-content-center">
        <Dialog
          header="Cart items"
          visible={visible}
          className="payment-alert"
          onHide={() => setVisible(false)}>
          <div className="card border-0 rounded-0 p-lg-4 bg-light">
            <div className="card-body">
              <h5 className="text-uppercase mb-4">Your order</h5>
              <ul className="list-unstyled mb-0">
                {cart_items &&
                  cart_items.map((item, index) => {
                    return (
                      <>
                        <li
                          key={item?.cart_uuid}
                          className="d-flex align-items-center justify-content-between p-2">
                          <strong className="small font-weight-bold">
                            {item?.product.product_name}
                          </strong>
                          <span className="text-muted small">
                            GH₵ {item?.total}
                          </span>
                          <span className="text-muted small">
                            <MdOutlineDeleteForever
                              color="red"
                              size={20}
                              onClick={() => deleteCart(item?.cart_uuid)}
                            />
                          </span>
                        </li>
                      </>
                    );
                  })}

                <li className="border-bottom my-2" />

                <div className="container-fluid py-5">
                  <button
                    type="button"
                    onClick={() => setVisible(false)}
                    className="btn btn-primary btn-sm btn-block">
                    proceed
                  </button>
                </div>
              </ul>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default CartAlert;
