import axios from "axios";
import { useState } from "react";
import Styles from "./search.module.css";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { ToastContainer, toast } from "react-toastify";

const AdvanceSearch = () => {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [record, setRecord] = useState("");

  // @ desc - a function to handle advance search
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/advance_search/`,
        {
          product_name: name,
          record_name: record,
          offset: "0",
          record_number: "50",
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
          },
        }
      )
      .then((res) => {
        if (res.data.products.length === 0) {
          toast.warn(
            "No Product(s) Found",
            {
              position: toast.POSITION.TOP_RIGHT,
            },
            { autoClose: 10000 }
          );
        } else {
          navigate("/search/results", {
            state: { data: res.data.products },
          });
        }
      })
      .catch((error) => {
        alert("No record found");
        window.location.reload();
      });
  };

  return (
    <>
      <ToastContainer />

      <Header />

      <div className="container">
        <div className="container py-3">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-7">
                <label>Search by name</label>
                <input
                  type="text"
                  className="form-control  mb-3"
                  placeholder="Any item name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label> Select a category </label>
                <select
                  className="form-control mb-3"
                  id={Styles.records}
                  style={{ width: "100% !important" }}
                  onChange={(e) => setRecord(e.target.value)}>
                  <option value=""> -- </option>
                  <option value="product">Product</option>
                  {/* <option value="service">Service</option> */}
                </select>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              id={Styles.search_btn}>
              Search
            </button>
          </form>
        </div>
        <br />
      </div>

      <Footer />
    </>
  );
};

export default AdvanceSearch;
