import axios from "axios";
import { useState, useEffect } from "react";
const BaseUrl = process.env.REACT_APP_Base_url;
const defaultToken =
  "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token";

export const useFetch = (url) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    let isMounted = false;
    axios
      .get(`${BaseUrl}${url}`, {
        headers: {
          "x-user-pass-auth": defaultToken,
        },
      })
      .then((res) => {
        if (!isMounted && res.data?.status === 200) {
          // console.log(res.data);
          setResponse(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // a cleanup function to set mounted to true to avoid setting state on an unmounted component
    return () => {
      isMounted = true;
    };
  }, [url]);

  return [response];
};

export const usePost = (url, data) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    let isMounted = false;
    axios
      .post(
        `${BaseUrl}${url}`,
        {
          company_id: data,
        },
        {
          headers: {
            "x-user-pass-auth": defaultToken,
          },
        }
      )
      .then((res) => {
        if (!isMounted && res.data?.status === 200) {
          // console.log(res.data);
          setResponse(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // a cleanup function to set mounted to true to avoid setting state on an unmounted component
    return () => {
      isMounted = true;
    };
  }, [url, data]);

  return [response];
};

export const useFetchWithoutToken = (url) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    let isMounted = false;
    axios
      .get(`${BaseUrl}${url}`)
      .then((res) => {
        // console.log(res.data);
        if (!isMounted) {
          setResponse(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // a cleanup function to set mounted to true to avoid setting state on an unmounted component
    return () => {
      isMounted = true;
    };
  }, [url]);

  return [response];
};

export const usePostWithoutToken = (url, data) => {
  const [response, setResponse] = useState(null);
  console.log("first", data);
  axios
    .post(`${BaseUrl}${url}`, data)
    .then((res) => {
      // console.log(res.data);
      setResponse(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

  return [response];
};

// export const usePostWithToken = (url, data) => {
//   const [response, setResponse] = useState(null);
//   const [token, setToken] = useState(null);

//   useEffect(() => {
//     const tk = sessionStorage.getItem("token");
//     setToken(tk);
//   }, [token]);

//   useEffect(() => {
//     axios
//       .post(`${BaseUrl}${url}`, data, {
//         headers: {
//           "x-user-pass-auth": token,
//         },
//       })
//       .then((res) => {
//         // if (res.data?.status === 200) {
//         console.log(res.data);
//         setResponse(res.data);
//         // }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, [url, token, data]);

//   return [response];
// };

// export const useFetchWithToken = (url) => {
//   const [response, setResponse] = useState(null);
//   const [token, setToken] = useState(null);

//   useEffect(() => {
//     const tk = sessionStorage.getItem("token");
//     setToken(tk);
//   }, [token]);

//   useEffect(() => {
//     axios
//       .get(`${BaseUrl}${url}`, {
//         headers: {
//           "x-user-pass-auth": token,
//         },
//       })
//       .then((res) => {
//         if (res.data?.status === 200) {
//           console.log(res.data);
//           setResponse(res.data);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, [url, token]);

//   return [response];
// };
