import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CompStyle from "./relatedProduct.module.css";

const RelatedProducts = ({ product_value }) => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    let isMounted = false;

    if (product_value) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/get_product_posts/`,
          {
            product_cat_id: product_value,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((res) => {
          if (!isMounted) {
            setDetails(res.data.product_posts.slice(0, 6));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      isMounted = true;
    };
  }, [product_value]);

  return (
    <div>
      <h2 className="h5 text-uppercase mb-4" id={CompStyle.related_h5}>
        Related products
      </h2>
      <section className="container-fluid">
        <div className="row">
          {/* PRODUCT*/}
          {details ? (
            details.map((detail) => {
              return (
                <div
                  className="col-lg-2 col-md-3 col-6 p-1"
                  key={detail?.product_uuid}>
                  <div className="product skel-loader">
                    <div className="mb-3 position-relative">
                      {detail?.discount === "0.00" ? null : (
                        <div className="badge text-white badge-danger">
                          {detail?.discount}%
                        </div>
                      )}

                      <Link
                        className="d-block"
                        to={`/product/details?items_id=${detail?.product_uuid}`}>
                        <img
                          className={CompStyle.pro}
                          src={detail?.image_url}
                          alt="..."
                          loading="lazy"
                        />
                      </Link>

                      <div className="product-overlay"></div>
                    </div>
                    <h6 style={{ fontSize: "14px" }}>
                      {" "}
                      <Link
                        className="reset-anchor"
                        to={`/product/details?items_id=${detail?.product_uuid}`}>
                        {detail?.product_name.length < 21
                          ? `${detail?.product_name}`
                          : `${detail?.product_name.substring(0, 20)}..`}
                      </Link>
                    </h6>

                    <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                      {/* am conditionally rendering the discount price if not display the real price */}
                      <span className="px-1">GH₵ {detail?.new_price}</span>

                      {detail?.discount !== "0.00" ? (
                        <del className="px-2" style={{ color: "red" }}>
                          GH₵ {""} {detail?.old_price}
                        </del>
                      ) : null}
                    </p>
                    <hr />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="">
              <p className="text-center">No Records Found</p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default RelatedProducts;
