import Trend from "./productsTable.module.css";
import { Link } from "react-router-dom";
import React from "react";

const ProductsTable = ({ data }) => {
  return (
    <>
      {data && (
        <div className="row blog">
          <div className="col-md-12">
            <div className="row">
              {data.map((item) => (
                <React.Fragment key={item.product_uuid}>
                  {/* The validation here is to remove all items with 0 in Stock  */}
                  {item.stock_number === 0 ? null : (
                    <div
                      className="col-lg-2 col-md-3 col-6 p-1"
                      key={item.product_uuid}>
                      <div className="product">
                        <div className="mb-3 position-relative">
                          {item?.discount === "0.00" ? null : (
                            <div className="badge text-white badge-danger">
                              {item?.discount}%
                            </div>
                          )}

                          <Link
                            className="d-block"
                            to={`/product/details?items_id=${item?.product_uuid}`}>
                            <img
                              className={Trend.pro}
                              src={item?.image_url}
                              alt="..."
                              loading="lazy"
                            />
                          </Link>
                          <div className="product-overlay"></div>
                        </div>

                        <h6 className="mb-0" id={Trend.trend_h6}>
                          <Link
                            className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                            to={`/product/details?items_id=${item?.product_uuid}`}>
                            {item?.product_name.length < 21
                              ? `${item?.product_name}`
                              : `${item?.product_name.substring(0, 18)}..`}
                          </Link>
                        </h6>

                        <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                          {/* am conditionally rendering the discount price if not display the real price */}
                          <span className="px-1">GH₵ {item?.new_price}</span>

                          {item?.discount !== "0.00" ? (
                            <del className="px-1" style={{ color: "red" }}>
                              GH₵ {""} {item?.old_price}
                            </del>
                          ) : null}
                        </p>

                        <Link
                          to={`/company?company_id=${item?.company.company_uuid}`}
                          className="text-decoration-none px-1 d-flex justify-content-start">
                          {item?.company.company_name.length < 20
                            ? `${item?.company.company_name}`
                            : `${item?.company.company_name.substring(
                                0,
                                19
                              )}..`}
                        </Link>
                        <hr />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsTable;
