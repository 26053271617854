import "./sell.css";

const Sell = () => {
  return (
    <div className="demo11" style={{ height: "100vh" }}>
      <div className="container">
        <h4 className="pricing_head">PRICING TABLE </h4>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="pricingTable11 green">
              <div className="pricingTable-header">
                <i className="fa fa-briefcase" />
                <div className="price-value">
                  {" "}
                  GH¢6.00{" "}
                  <span className="month">
                    per product / services a day
                  </span>{" "}
                </div>
              </div>
              <h3 className="heading">Business standard</h3>
              <div className="pricing-content">
                <ul>
                  <li>
                    Registration fees one time payment:{" "}
                    <b style={{ color: "#40c952" }}>NA</b>{" "}
                  </li>
                  <li>
                    Advertise slot in month :
                    <b style={{ color: "#40c952" }}>Unlimited</b>{" "}
                  </li>
                  <li>
                    Personal Account management:{" "}
                    <b style={{ color: "#40c952" }}>Yes</b>
                  </li>
                  <li>
                    Account Dashboard: <b style={{ color: "#40c952" }}>Yes</b>{" "}
                  </li>
                  <li>
                    Charges Added to Product:{" "}
                    <b style={{ color: "#40c952" }}>6% of product price</b>
                  </li>
                  {/* <li>
                      Pay per post:{" "}
                      <b style={{ color: "#40c952" }}>1 month free promo</b>{" "}
                    </li> */}
                </ul>
              </div>
              <div className="pricingTable-signup">
                <a href="https://rosmallonline.com:7000/register">sign up</a>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="pricingTable11">
              <div className="pricingTable-header">
                <i className="fa fa-adjust" />
                <div className="price-value">
                  {" "}
                  GH¢0.00 <span className="month">per month</span>{" "}
                </div>
              </div>
              <h4 className="heading">Pay as you Go (only for products)</h4>
              <div className="pricing-content">
                <ul>
                  <li>
                    Registration fees one time payment:{" "}
                    <b style={{ color: "#ffa442" }}>NA</b>{" "}
                  </li>
                  <li>
                    Advertise slot in month:{" "}
                    <b style={{ color: "#ffa442" }}>Unlimited</b>
                  </li>
                  <li>
                    Personal Account management:{" "}
                    <b style={{ color: "#ffa442" }}>Yes</b>
                  </li>
                  <li>
                    Account Dashboard: <b style={{ color: "#ffa442" }}>Yes</b>
                  </li>
                  <li>
                    Charges Added to Product:{" "}
                    <b style={{ color: "#ffa442" }}>15% of product price</b>
                  </li>
                  {/* <li>
                      Pay per post:{" "}
                      <b style={{ color: "#ffa442" }}>
                        5% of Product sale
                        1 month free promo
                      </b>
                    </li> */}
                </ul>
              </div>
              <div className="pricingTable-signup">
                <a href="https://rosmallonline.com:7000/register">sign up</a>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="pricingTable11 red">
              <div className="pricingTable-header">
                <i className="fa fa-cube" />
                <div className="price-value">
                  {" "}
                  GH¢350.00 <span className="month">per month</span>{" "}
                </div>
              </div>
              <h3 className="heading">Business Plus</h3>
              <div className="pricing-content">
                <ul>
                  <li>
                    Registration fees one time payment:{" "}
                    <b style={{ color: "#ff4b4b" }}>NA</b>{" "}
                  </li>
                  <li>
                    Advertise slot in month:{" "}
                    <b style={{ color: "#ff4b4b" }}>12 products/services</b>
                  </li>
                  <li>
                    Personal Account management:{" "}
                    <b style={{ color: "#ff4b4b" }}>Yes</b>{" "}
                  </li>
                  <li>
                    Account Dashboard: <b style={{ color: "#ff4b4b" }}>Yes</b>{" "}
                  </li>
                  <li>
                    Charges Added to Product:{" "}
                    <b style={{ color: "#ff4b4b" }}>6% of product price</b>
                  </li>
                  {/* <li>
                      Pay per post:{" "}
                      <b style={{ color: "#ff4b4b" }}>
                        NA1 month free promo
                      </b>{" "}
                    </li> */}
                </ul>
              </div>
              <div className="pricingTable-signup">
                <a href="https://rosmallonline.com:7000/register">sign up</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sell;
