import { ToastContainer, toast } from "react-toastify";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { Link, useLocation } from "react-router-dom";
import ProStyle from "./productSubs.module.css";
import ReactPagination from "react-paginate";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Filter from "../../filter/filter";
import queryString from "query-string";
import axios from "axios";

const ProductSubs = () => {
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(0);
  const [productSubs, setProductSubs] = useState([]);
  const { subCategories } = useSelector((state) => state.shopper);

  const { search } = useLocation();
  const { sub_cat_id } = queryString.parse(search);

  useEffect(() => {
    if (sub_cat_id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/get_product_sub_category_posts/`,
          {
            product_sub_cat_id: sub_cat_id,
            record_number: "",
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((response) => {
          if (response.data.message === "Record(s) Not Found") {
            setProductSubs([]);
            toast.warn(
              "No Product(s) Found",
              {
                position: toast.POSITION.TOP_RIGHT,
              },
              { autoClose: 10000 }
            );
          } else {
            setProductSubs(response.data.product_sub_posts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [sub_cat_id]);

  const usersPerPage = 18;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = productSubs
    ? Math.ceil(productSubs.length / usersPerPage)
    : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // using regex to remove the last word from the pathname
  const path = location.pathname.replace(/\/[^\/]*$/, "");

  return (
    <>
      <ToastContainer />

      <div className="page-holder">
        <Header />

        <div className="container">
          <section className="py-5">
            <div className="container p-0">
              <div className="row">
                {/* SHOP SIDEBAR*/}
                <div className="col-lg-2 order-2 order-lg-1">
                  <h5 className="text-uppercase mb-4">Categories</h5>
                  <div className="py-2 px-4 bg-dark text-white mb-3">
                    <strong className="small text-uppercase font-weight-bold">
                      Products
                    </strong>
                  </div>

                  <ul className="list-unstyled small text-muted font-weight-normal bg-light">
                    {subCategories &&
                      subCategories.map((item) => {
                        return (
                          <li
                            className="mb-0"
                            key={item.product_sub_category_id}>
                            <Link
                              className="dropdown-item"
                              to={`${path}/${item.sub_category_name.toLowerCase()}?sub_cat_id=${
                                item.product_sub_category_id
                              }`}>
                              {item.sub_category_name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>

                  <div className="price-range pt-4 mb-5">
                    <Filter />
                  </div>
                </div>
                {/* SHOP LISTING*/}
                <div className="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">
                  <div className="row mb-3 align-items-center" id="sort">
                    <div className="col-lg-6 mb-2 mb-lg-0">
                      {productSubs && (
                        <p className="text-small text-muted mb-0">
                          Showing {productSubs.length} results
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {productSubs &&
                      productSubs
                        .slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((items) => (
                          <div
                            className="col-lg-3 col-md-4 col-6 p-1"
                            key={items.product_uuid}>
                            <div className="card">
                              <div className="product pb-3">
                                <div className="mb-3 position-relative">
                                  {items.discount === "0.00" ? null : (
                                    <div className="badge text-white badge-danger">
                                      {items.discount}%
                                    </div>
                                  )}

                                  <Link
                                    className="d-block"
                                    to={`/product/details?items_id=${items?.product_uuid}`}
                                    state={{ data: items }}>
                                    <img
                                      className={ProStyle.pro}
                                      src={items.image_url}
                                      alt="..."
                                      loading="lazy"
                                    />
                                  </Link>
                                  <div className="product-overlay"></div>
                                </div>

                                <h6 className="mb-0">
                                  <Link
                                    className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                                    to={`/product/details?items_id=${items?.product_uuid}`}
                                    state={{ data: items }}>
                                    {items.product_name.length < 25
                                      ? `${items.product_name}`
                                      : `${items.product_name.substring(
                                          0,
                                          23
                                        )}...`}
                                  </Link>
                                </h6>

                                <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                                  {/* am conditionally rendering the discount price if not display the real price */}
                                  <span className="px-1">
                                    GH₵ {items.new_price}
                                  </span>

                                  {items.discount !== "0.00" ? (
                                    <del
                                      className="px-2"
                                      style={{ color: "red" }}>
                                      GH₵ {items.old_price}
                                    </del>
                                  ) : null}
                                </p>

                                <Link
                                  to="/company"
                                  state={{ data: items.company }}
                                  className="text-decoration-none px-1 d-flex justify-content-start">
                                  {items.company.company_name.length < 20
                                    ? `${items.company.company_name}`
                                    : `${items.company.company_name.substring(
                                        0,
                                        19
                                      )}..`}
                                </Link>
                                {/* <hr /> */}
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                  {/* PAGINATION*/}
                  {productSubs && (
                    <div className="container">
                      <ReactPagination
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        breakLabel={"..."}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ProductSubs;
